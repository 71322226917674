import {Loader} from "../components/Loader";
import {useEffect, useState} from "react";
import {
    ErrorMsg, 
    SOURCE, 
    USER_MESSAGES_ERROR, 
    CONSOLE_MESSAGES_ERROR
} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {setAuth, setUser} from "../store/slices/userSlice";
import {useNavigate} from "react-router";

export default function Auth() {
    const [state, setState] = useState({
        loading: true,
        errorMsg: ""
    })
    const user = useSelector(state => state.userSlice.user)
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        const code = new URLSearchParams(window.location.search).get('code');
        if (!code) {
            setState(state => ({loading: false, errorMsg: "Неверный код авторизации"}))
            return
        }
        fetch(`${SOURCE.url}/api/v1/auth/tokenByHhCode?code=${code}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            setState(state => ({...state, loading: false}))
            if (res.error) {
                setState(state => ({...state, errorMsg: res.error}))
            } else {
                dispatch(setAuth({access_token: res}))
                fetch(`${SOURCE.url}/api/v1/personalDetails/import`, {
                    method: 'POST',
                    headers: {
                        'Authorization': res,
                        'Content-Type': 'application/json'
                    }
                }).then(() => user.access_token ? refreshPersonalDetails(user, dispatch, setState, navigate) : setState(p => ({ ...p, errorMsg: 'Ошибка при авторизации' })))
                navigate("/select_resume")
            }
        }).catch(e => {
            setState(state => ({...state, errorMsg: USER_MESSAGES_ERROR.AUTH}))
            console.error(CONSOLE_MESSAGES_ERROR.AUTH, e)
        })
 
    }, [dispatch, navigate])

    return <>
        {state.location && <Loader/>}
        {state.errorMsg && <ErrorMsg state={state} setState={setState}/>}
    </>
}

export const refreshPersonalDetails = (user, dispatch, setState, navigate) => {
    return fetch(SOURCE.url + `/api/v1/user/${user.id}`, {
        method: 'GET',
        headers: {
            'Authorization': user.access_token,
            'Content-Type': 'application/json'
        }
    }).then(res => res.json()).then(res =>{
        if (res.error || res.details ) {
            const errObj = new Error()
            throw errObj
        }
        else dispatch(setUser({  ...user, personalDetails: res.personalDetails, loaded: true }))
    }).catch(e => {
        console.log(e)
        setState(p => ({ ...p, errorMsg: 'Ошибка при авторизации' }))
        navigate('/logout')
    }) 
}