import {useEffect, useRef} from "react";

export default function InputAutosize({minWidth, defaultWidth, ...props}) {
    const inputRef = useRef();
    const updateSize = (el) => {
        if (el.value) {
            el.style.width = minWidth;
            el.style.width = el.scrollWidth + "px";
        } else el.style.width = defaultWidth;
    }
    useEffect(() => {
        updateSize(inputRef.current);
    }, [props.value]);
    return <input {...props} style={{...props.style, minWidth, width: defaultWidth, transition: "none"}} ref={inputRef}
                  onChange={(e) => {
                      !props.value && updateSize(e.currentTarget);
                      props.onChange && props.onChange(e);
                  }}/>
}