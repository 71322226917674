import {useLocation, useNavigate} from "react-router";
import {COLORS, MAX_LANDING_WIDTH, MAX_WIDTH, PADDINGS, SOURCE} from "../constants"
import {ReactComponent as TelegramSvg} from '../icons/telegram_circle.svg'
import {ReactComponent as VKSvg} from '../icons/VK.svg'

export const Footer = () => {
    const loc = useLocation()
    return (
        <footer className={'footer mt-auto py-4 z-1' + PADDINGS}
                style={{minHeight: '100px', width: '100%', backgroundColor: COLORS.dark_primary}}>
            <div className="w-100 mx-auto" style={{maxWidth: loc.pathname === '/' ? MAX_LANDING_WIDTH : MAX_WIDTH }}>
                <div className='d-flex flex-row flex-wrap'>
                    <div className="me-auto mb-3 me-5">
                        <img className="mb-2" src={SOURCE.source_url + 'brand_footer.svg'} alt="brand-footer"/>
                        <p className="fs-6" style={{color: 'white'}}>Сервис поиска работы</p>
                        <div className="d-flex flex-row mt-2 mt-md-4">
                            <TelegramSvg className="me-2" cursor='pointer' height='30px'
                                         onClick={() => window.open('https://t.me/hurtleru')}/>
                            <VKSvg height='30px' cursor='pointer'
                                   onClick={() => window.open('https://vk.com/hurtleru')}/>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap">
                        <div className="d-flex flex-column text-md-end me-3 mb-3">
                            <p className="mb-2 underline" style={{color: COLORS.header_text}}>Документы</p>
                            <p onClick={() => window.open('https://hurtle.ru/policy')}
                               className="fs-6 text-wrap mb-1 underline"
                               style={{color: 'white', cursor: 'pointer'}}>Политика обработки персональных данных</p>
                            <p onClick={() => window.open('https://hurtle.ru/policy')} className="fs-6 text-wrap underline"
                               style={{color: 'white', cursor: 'pointer'}}>Соглашение об оказании услуг</p>
                        </div>
                        <div className="d-flex flex-column text-md-end mb-3">
                            <p className="mb-2" style={{color: COLORS.header_text}}>О Хартле</p>
                            <p onClick={() => window.open('https://hurtle.ru/policy')} className="fs-6 text-wrap mb-1 underline"
                               style={{color: 'white', cursor: 'pointer'}}>Наша миссия</p>
                            <p onClick={() => window.open('https://hurtle.ru/contacts')}
                               className="fs-6 text-wrap mb-1 underline"
                               style={{color: 'white', cursor: 'pointer'}}>Контакты</p>
                            <p onClick={() => window.open('https://hurtle.ru/contacts')} className="fs-6 text-wrap underline"
                               style={{color: 'white', cursor: 'pointer'}}>Ресурсы бренда</p>
                        </div>
                    </div>

                </div>
                <p className="mt-2 mt-md-3 fs-6 text-wrap" style={{color: COLORS.header_text}}>© 2023, ООО "Хартл",
                    официальный сайт</p>
            </div>
        </footer>
    )
}
