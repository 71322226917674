import {SOURCE} from "../../constants";
import {setResume} from "../../store/slices/resumeSlice";

export const getResumeAvatar = async (access_token, resumeId) => {
    const res = await fetch(SOURCE.url + "/api/v1/resume/" + resumeId + "/avatar", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": access_token
        }
    });
    if (!res.ok) return null;
    else return res.blob();
}

export const updateUserResume = async (access_token, dispatch, resumeId) => {
    const res = await fetch(SOURCE.url + "/api/v1/resume/" + resumeId, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": access_token
        }
    });
    if (!res.ok) return;
    const data = await res.json();
    data.experience = (data.experience || []).map(ex => ({...ex, startMonth: ex.startMonth ? ex.startMonth.toString().padStart(2, "0") : null, endMonth: ex.endMonth ? ex.endMonth.toString().padStart(2, "0") : null}));
    data.loaded = true;
    await dispatch(setResume(data));
    return data;
}