import {useEffect, useState} from "react";
import {ReactComponent as ArrowDownIcon} from "../../icons/arrow-down.svg";
import Modal from "react-bootstrap/Modal";
import {
    COLORS,
    CONTACT_LABELS,
    CONTACT_TYPE,
    PLACEHOLDER_CONTACT,
    typeAttributeByContactType,
    useDebounceCallback
} from "../../constants";
import {useDispatch} from "react-redux";
import InputMask from "react-input-mask";
import {createContact, deleteContact, editContact, updateServerContactInner} from "./api";

export const ContactMenuSelect = ({ user, resume }) => {
    const dispatch = useDispatch();
    const [modalShown, setModalShown] = useState(false);
    const [availableTypes, setAvailableTypes] = useState([]);
    const updateResumeContact = useDebounceCallback(updateServerContactInner, 500);

    const showModal = () => setModalShown(true);
    const hideModal = () => setModalShown(false);

    const makeInputHandler = (id) => (e) => editContact(
        user.access_token,
        id,
        {value: e.target.value},
        resume.contacts,
        dispatch,
        updateResumeContact
    );

    const makeBlurHandler = (id) => (e) => {
        if (!e.target.value) deleteContact(user.access_token, id, resume.contacts, dispatch);
    };

    useEffect(() => {
        setAvailableTypes(Object.keys(CONTACT_TYPE).filter(t => resume.contacts.findIndex(c => c.type === t) === -1));
    }, [resume.contacts]);

    return <>
        <button className="fs-6 px-3 bg-white rounded-2" style={{height: "40px", border: "1px solid #e3e3e3"}} onClick={showModal}>
            Контакты
            <ArrowDownIcon className="ms-5" />
        </button>
        <Modal centered contentClassName='border-0 rounded-4 px-2 m-3' show={modalShown} onHide={hideModal}>
            <Modal.Header closeButton style={{ borderBottom: '0 none' }}>
                <Modal.Title className='fs-4' style={{ fontWeight: '600' }}>
                    Контакты
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { resume && <div className="w-100 d-flex flex-column gap-2">
                    {resume.contacts.map(contact => <div className="d-flex flex-row w-100">
                        <p className="fs-6 fw-semibold">{CONTACT_LABELS[contact.type]}:&nbsp;</p>
                        {contact.type === CONTACT_TYPE.PHONE ?
                            <InputMask
                                type="tel"
                                className="flex-grow-1 no_outline"
                                mask="+7 (999) 999-99-99"
                                placeholder={PLACEHOLDER_CONTACT[contact.type]}
                                value={contact.value}
                                onChange={makeInputHandler(contact.id)}
                                onBlur={makeBlurHandler(contact.id)}
                            /> :
                            <input
                                type={typeAttributeByContactType(contact.type)}
                                className="flex-grow-1 no_outline"
                                placeholder={PLACEHOLDER_CONTACT[contact.type]}
                                value={contact.value}
                                onChange={makeInputHandler(contact.id)}
                                onBlur={makeBlurHandler(contact.id)}
                            />}
                    </div>) }
                    { resume && <div className="w-100 d-flex flex-row flex-wrap gap-3 mt-3">
                        { (availableTypes || []).map((t, i) => <span
                            key={i}
                            className="fs-6 fw-medium px-3 py-2 rounded-2"
                            style={{backgroundColor: COLORS.light_neutral, cursor: "pointer"}}
                            onClick={() => createContact(user.access_token, t, resume, dispatch)}>
                            {CONTACT_LABELS[t]}
                        </span>) }
                    </div> }
                </div> }
            </Modal.Body>
            <Modal.Footer style={{ borderTop: '0 none' }}>
                <button className="w-100 px-3 py-2 rounded-1 fs-6" style={{backgroundColor: COLORS.neutral}} onClick={hideModal}>
                    Закрыть
                </button>
            </Modal.Footer>
        </Modal>
    </>
}