import {ReactComponent as IconPhone} from '../../../icons/icon_phone.svg';
import {ReactComponent as IconMail} from '../../../icons/icon_mail.svg';
import styles from './styles.module.css'

export const HeaderPage = ({mail, phone, firstname, lastname, profession}) => {
    const firstLetter = firstname[0];
    const lastLetter = lastname[0];
    
    return (
            <div className='row mb-4' style={{paddingTop: '35px'}}>
                <div className='col m-auto d-flex' style={{maxWidth: '484px'}}>
                    <div className='d-flex flex-column align-items-center justify-content-center' style={{width: '24px', height: '32px', backgroundColor: 'rgba(103, 87, 241, 1)', marginRight: '10px'}}>
                        <p className={`${styles.initial}`}>{firstLetter}</p>
                        <p className={`${styles.initial}`}>{lastLetter}</p>
                    </div>
                    <div className='d-flex flex-column'>
                        <p className='fs-4 fw-bold mb-0'>
                            {firstname + ' ' + lastname}</p>
                        <p className='fs-5 fw-normal' style={{maxWidth: '190px'}}>{profession}</p>
                    </div>
                </div>
                <div className={`col-auto fs-6 m-auto ${styles.contacts}`} style={{maxWidth: '484px'}}>
                    <div className='d-flex flex-nowrap align-items-center'>
                        <div>
                            <IconMail/>
                        </div>
                        <p style={{marginLeft: '10px'}}>{mail}</p>
                    </div>
                    <div className='d-flex flex-nowrap align-items-center'>
                        <div>
                            <IconPhone/>
                        </div>
                        <p style={{marginLeft: '10px'}}>{phone}</p>
                    </div>
                </div>
            </div>
    )
}