import {COLORS} from "../constants"

export const Loader = ({className = '', size = '', color = COLORS.primary, animation = true, ...props}) => {
    return (<div className={'m-auto text-center ' + (animation ? 'loader' : '')}>
        {size === 'small' ?
            <span className={"spinner-border spinner-border-sm " + className} style={{color: color}} role="status"
                  aria-hidden="true" {...props}></span> :
            <div className="spinner-border" style={{color: color}} role="status">
                <span className="visually-hidden ">Loading...</span>
            </div> // loader
        }
    </div>)
}