import {Alert} from 'react-bootstrap';
import {useEffect, useState} from "react";

export const MONTHS = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']
export const FULL_MONTHS = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
export const SHORT_MONTHS = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июн.', 'июл.', 'авг.', 'сент.', 'окт.', 'ноя.', 'дек.']
export const PASTEL_COLORS = ['#FFFCB0', '#D4FFC0', '#D1F4FF', '#FDD0B7']
export const AVATARS_BACKGROUNDS = [
    'radial-gradient(50% 50% at 50% 50%, rgba(248, 255, 170, 0.50) 0%, rgba(176, 232, 142, 0.50) 100%)',
    'radial-gradient(50% 50% at 50% 50%, #AAFFC7 0%, #8EE8D2 100%',
    'radial-gradient(50% 50% at 50% 50%, #ACAAFF 0%, #B58EE8 100%',
    'radial-gradient(50% 50% at 50% 50%, #F8FFAA 0%, #B0E88E 100%'
]

export const MAX_WIDTH = '1400px'
export const MAX_LANDING_WIDTH = '1500px'

export const PADDINGS = ' px-3 px-lg-5'

export const CONTACT_TYPE = {
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
    TELEGRAM: 'TELEGRAM',
    GITHUB: 'GITHUB',
    VK: 'VK',
    FACEBOOK: 'FACEBOOK',
    LINKEDIN: 'LINKEDIN',
    OTHER: 'OTHER'
}

export const PLACEHOLDER_CONTACT = {
    EMAIL: 'example@gmail.com',
    PHONE: '+7(000)000-00-00',
    TELEGRAM: '@nickname',
    GITHUB: 'https://github.com/user',
    VK: 'https://vk.com/id',
    FACEBOOK: 'https://www.facebook.com/',
    LINKEDIN: 'https://www.linkedin.com/',
    OTHER: 'user_contact'
}

export const USER_MESSAGES_ERROR = {
    UPDATE_RESUME: 'Ошибка сервера при обновлении резюме',
    UPDATE_SERTIFICATE: 'Ошибка сервера при обновлении сертификата',
    UPDATE_EXPERIENCE: 'Ошибка сервера при обновлении опыта работы',
    UPDATE_LANGUAGE: 'Ошибка при обновлении языка',
    UPDATE_PERSONAL_DETAILS: 'Ошибка при обновлении имени или фамилии',
    UPDATE_CONTACT: 'Ошибка при обновлении контакта',

    CREATE_CERTIFICATE: 'Ошибка при создании сертификата, попробуйте еще раз',
    CREATE_EDUCATION: 'Ошибка при создании образования, попробуйте еще раз',
    CREATE_EXPERIENCE: 'Ошибка при создании опыта работы, попробуйте еще раз',
    CREATE_LANGUAGE: 'Ошибка при создании языка, попробуйте еще раз',
    CREATE_CONTACT: 'Ошибка при создании контакта, попробуйте еще раз',

    DELETE_CERTIFICATE: 'Ошибка при удалении сертификата, попробуйте еще раз',
    DELETE_EDUCATION: 'Ошибка при удалении образования, попробуйте еще раз',
    DELETE_EXPERIENCE:'Ошибка при удалении опыта работы, попробуйте еще раз',
    DELETE_LANGUAGE: 'Ошибка при удалении языка, попробуйте еще раз',
    DELETE_CONTACT: 'Ошибка при удалении контакта, попробуйте еще раз',

    UPLOAD_AVATAR_TOO_LARGE: "Файл слишком большой, максимальный размер 5Мб",
    UPLOAD_AVATAR: "Ошибка при сохранении аватара, попробуйте еще раз",

    GET_ALL_RESUME: 'Ошибка сервера при получении всех резюме',
    IMPORT_RESUME: 'Ошибка сервера при импорте резюме',
    AUTH: 'Ошибка сервера при авторизации'
}

export const CONSOLE_MESSAGES_ERROR = {
    UPDATE_RESUME: 'Error during updateServerResume:',
    UPDATE_SERTIFICATE: 'Error during updateServerCertificate:',
    UPDATE_EXPERIENCE: 'Error during updateServerExperience',
    UPDATE_LANGUAGE: 'Error during updateServerLanguage',
    UPDATE_PERSONAL_DETAILS: 'Error during updateServerPersonalDetails',
    UPDATE_CONTACT: 'Error during updateContact',

    CREATE_CERTIFICATE: 'Error during createCerificate',
    CREATE_EDUCATION: 'Error during createEducation',
    CREATE_EXPERIENCE: 'Error during createExperience',
    CREATE_LANGUAGE: 'Error during createLanguage',
    CREATE_CONTACT: 'Error during createContact',
    
    DELETE_CERTIFICATE: 'Error during deleteServerCertificate',
    DELETE_EDUCATION: 'Error during deleteServerEducation',
    DELETE_EXPERIENCE:'Error during deleteServerExperience',
    DELETE_LANGUAGE: 'Error during deleteServerLanguage',
    DELETE_CONTACT: 'Error during deleteContact',

    GET_ALL_RESUME: 'Error during all resume',
    IMPORT_RESUME: 'Error during import resume',
    AUTH: 'Error during authorization',
    
}

export function calculateAge(birthDate) {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }

  let ageString;
  if (age % 10 === 1 && age !== 11) {
    ageString = age + " год";
  } else if ((age % 10 === 2 || age % 10 === 3 || age % 10 === 4) && (age < 10 || age > 20)) {
    ageString = age + " года";
  } else {
    ageString = age + " лет";
  }
  return ageString;
}

  

export const COLORS = {
    primary: '#6757F1', //'#7268CB',
    light_primary: '#F1F0FA',
    red_primary: '#F8485D',
    red_light_primary: '#FFF9FA',
    dark_primary: '#18191B',
    text_primary: '#5246B8',
    hover_primary: '#4C3EC9',
    muted_primary: '#8b7ff4',
    secondary: '#FAFAFD',
    danger: '#F8485D',
    header_text: '#849095',
    warning: '#B8B0FF',
    success: '#038900',
    footer: '#272727',
    input_placeholder: '#849095',
    light_neutral: '#F1F1F1',
    neutral: '#E8E8EC',
    gray: '#E9F0F6',
    orange: '#F96D49',
    yellow: '#FECF77',
    black: '#18191B'

}
// export const COLORS = {
//     primary: '#6757F1', //'#7268CB',
//     light_primary: '#F1F0FA',
//     red_primary: '#F8485D',
//     red_light_primary: '#FFF9FA',
//     text_primary: '#5246B8',
//     hover_primary: '#4C3EC9',
//     danger: '#F8485D',
//     footer: '#272727', // '#F8F8FD'
//     secondary: '#FAFAFD',
//     text: '#272727',
//     second_text: '#6E6D75',
//     header_text: '#849095',
//     pastel_secondary: '#FFF9F9',
//     pastel: '#FBFBFF',
//     warning: '#FF9900',
//     success: '#01A707',
//     hr: '#C7C8C9',
//     light_neutral: '#F1F1F1',
//     neutral: '#E8E8EC',
//     accountBg: '#F8F7FB',
//     gray: '#E9F0F6',
//     orange: '#F96D49',
//     yellow: '#FECF77',
//     black: '#18191B'
// }

export const SOURCE = {
    url: 'https://cv.hurtle.ru',
    source_url: 'https://resource.hurtle.ru/',
}




export const ErrorMsg = ({setState, state}) => {
    setTimeout(() => setState(p => ({...p, errorMsg: ''})), 3000)
    return (
        <div className='position-fixed' style={{top: '10px', right: '10px', zIndex: '999999'}}>
            <Alert show={state.errorMsg !== ''} variant="danger" onClose={() => setState(p => ({...p, errorMsg: ''}))}
                   dismissible>
                <Alert.Heading>Ошибка</Alert.Heading>
                <p className='text-wrap'>
                    {state.errorMsg}
                </p>
            </Alert>
        </div>
    )
}

export const SuccessMsg = ({setState, state}) => {
    setTimeout(() => setState(p => ({...p, successMsg: ''})), 3000)
    return (
        <div className='position-fixed' style={{top: '10px', right: '10px', zIndex: '999999'}}>
            <Alert show={state.successMsg !== ''} variant="success"
                   onClose={() => setState(p => ({...p, successMsg: ''}))} dismissible>
                <Alert.Heading>Success!</Alert.Heading>
                <p className='text-wrap'>
                    {state.successMsg}
                </p>
            </Alert>
        </div>
    )
}

export const useDebounceCallback = (func, delay) => {
    const [args, setArgs] = useState(null);
    useEffect(() => {
        if (args === null) return;
        const timeout = setTimeout(() => func(...args), delay);
        return () => clearTimeout(timeout);
    }, [args]); // DO NOT add func and delay to deps
    return (...args) => setArgs(args);
}

export const Validation = {
    validName: name => /^[A-zА-яЁё]+$/i.test(name),
    validFio: fio => fio.trim().split(' ').length >= 2 && fio.trim().split(' ').length < 4 && fio.trim().split(' ').filter(el => !Validation.validName(el)).length === 0,
    validMail: mail => /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail), // /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i
    validPhone: phone => phone !== '' && !phone.includes('_'),
    validPassword: pswd => pswd.length >= 6, // /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}$/.test(pswd)
    validDate: date => date.length === 10,
    validTelegram: tg => tg !== '',
    validMask: mask => !mask.includes('_') && mask !== '',
    validYear: year => year.length <= 4,
}

export const CONTACT_LABELS = {
    EMAIL: "Почта",
    PHONE: "Телефон",
    TELEGRAM: "Telegram",
    GITHUB: "GitHub",
    VK: "VK",
    FACEBOOK: "Facebook",
    LINKEDIN: "LinkedIn",
    OTHER: "Другое"
}

export const typeAttributeByContactType = (type) => {
    switch (type) {
        case CONTACT_TYPE.EMAIL:
            return "email";
        case CONTACT_TYPE.PHONE:
            return "tel";
        default:
            return "text"
    }
}
