// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-select {
    color: #EA7000;
    border: 1px solid #E3E3E3;
    cursor: pointer;
    appearance: none;
    -moz-appearance: none;
    background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: calc(100% - 1rem);
}

.save-select:focus {
    color: #EA7000 !important;
    border: 1px solid #E3E3E3 !important;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/SaveResumeSelect/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,eAAe;IAEf,gBAAgB;IAChB,qBAAqB;IACrB,0EAA0E;IAC1E,4BAA4B;IAC5B,0BAA0B;IAC1B,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;IACzB,oCAAoC;IACpC,aAAa;AACjB","sourcesContent":[".save-select {\n    color: #EA7000;\n    border: 1px solid #E3E3E3;\n    cursor: pointer;\n    -webkit-appearance: none;\n    appearance: none;\n    -moz-appearance: none;\n    background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg');\n    background-repeat: no-repeat;\n    background-size: 14px 14px;\n    background-position: calc(100% - 1rem);\n}\n\n.save-select:focus {\n    color: #EA7000 !important;\n    border: 1px solid #E3E3E3 !important;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
