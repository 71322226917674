import {createSlice} from '@reduxjs/toolkit'

export const resumeSlice = createSlice({
    name: 'resume',
    initialState: {
        resume: {
            id: "",
            createdAt: "",
            city: "",
            isVisibleToEmployers: "",
            title: "",
            importedFrom: "",
            importedId: "",
            summary: "",
            skills: [],
            certificates: [],
            contacts: [],
            education: [],
            experience: [],
            languages: [],
            loaded: false
        }
    },
    reducers: {
        setResume: (state, action) => {
            state.resume = action.payload
        },
        setSkills: (state, action) => {
            state.resume.skills = action.payload
        },
        setCertificates: (state, action) => {
            state.resume.certificates = action.payload
        },
        setEducation: (state, action) => {
            state.resume.education = action.payload
        },
        setExperience: (state, action) => {
            state.resume.experience = action.payload
        },
        setLanguages: (state, action) => {
            state.resume.languages = action.payload
        },
        setContacts: (state, action) => {
            state.resume.contacts = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setResume, setSkills, setCertificates, setEducation, setExperience, setLanguages, setContacts} = resumeSlice.actions;

export default resumeSlice.reducer;