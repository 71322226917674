import styles from './styles.module.css'
export const HeaderPage = ({
    mail,
    phone,
    firstName,
    lastName,
    profession,
    city, 
    summary
}) => {
    return (
        <>
            <div className="row" style={{paddingTop: '35px'}}>
                <h2 className='fs-4 fw-normal'>{`${firstName} ${lastName}`}</h2>
            </div>

            <div className="row gy-2" style={{marginBottom: '10px'}}>
                <div className={`col-sm-5 ${styles.contacts}`}>
                    <div className='fw-6'>{profession}</div>
                    <div>{city}</div>
                    <div>{mail}</div>
                    <div>{phone}</div>
                </div>

                <p className={`col-sm-7 ${styles.about_self}`}>
                    {summary}
                </p>
            </div>
        </>
    )
}