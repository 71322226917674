import "./styles.css";
import {useDispatch, useSelector} from "react-redux";
import {updateUserResume} from "../../screens/ConstructorPage/utils";
import {PageOne} from "../PdfResumePages/pageOne/PageOne";
import {PageTwo} from "../PdfResumePages/pageTwo/PageTwo";
import {PageThree} from "../PdfResumePages/pageThree/PageThree";
import {renderToString} from "react-dom/server";
import html2pdf from "html2pdf.js";
import {useEffect} from "react";

export const SaveResumeSelect = ({resumeId, user}) => {
    const dispatch = useDispatch();

    async function selectHandler(e) {
        const resume = await updateUserResume(user.access_token, dispatch, resumeId);
        let downloadPage;
        if (e.target.value === "first") downloadPage = <PageOne props={resume} id={resumeId} user={user} />;
        else if (e.target.value === "second") downloadPage = <PageTwo props={resume} id={resumeId} user={user} />;
        else if (e.target.value === "third") downloadPage = <PageThree props={resume} id={resumeId} user={user} />;
        else return;
        const elem = renderToString(downloadPage);
        html2pdf().from(elem).set({filename: `${user.personalDetails.firstName} ${user.personalDetails.lastName}.pdf`}).save();
    }

    return <select className="w-100 px-3 py-2 mt-3 fw-medium rounded-2 save-select"
                   onClick={(e) => e.stopPropagation()} onChange={selectHandler}>
        <option value="none" defaultChecked={true} disabled={false}>Скачать резюме</option>
        <option value="first">Простой</option>
        <option value="second">Современный</option>
        <option value="third">Профессиональный</option>
    </select>
}