// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_section__ojU\\+E {
    width: max-content;
    padding-right: 10px;
    font-size: 1.2rem;
    white-space: nowrap;
    display: inline-block;
    font-weight: 600;
    color: #6757F1 !important;
}

.styles_sectionLine__LtKUb {
    width: 100%;
    border-bottom: 1.5px solid #6757F1 !important;
    display: inline-block;
}


.styles_about_self__1sQn-, .styles_contacts__vHnbi {
    font-size: 0.8rem;
}

.styles_jobTitle__o4Jr8 {
    font-size: 0.85rem;
    font-weight: 600;
    color: black !important;
}

.styles_nameCompany__8Mt-Z {
    font-weight: 600;
    font-size: 0.75rem;
    color: black !important;
}

.styles_experience__KcM2Z {
    font-size: 0.7rem;
    text-align: right;
}

.styles_text__LdXYx {
    font-size: 0.688rem;
    color: black !important;
}

.styles_list__9QzSK {
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.styles_education__kMK9Q, .styles_item__YkqNA {
    font-size: 0.688rem;
    font-weight: 600;
}

.styles_item__YkqNA {
    padding: 0 15px;
    border-right: 1px solid black;
}

.styles_item__YkqNA:last-child {
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/PdfResumePages/pageThree/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,6CAA6C;IAC7C,qBAAqB;AACzB;;;AAGA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,qBAAqB;IACrB,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".section {\n    width: max-content;\n    padding-right: 10px;\n    font-size: 1.2rem;\n    white-space: nowrap;\n    display: inline-block;\n    font-weight: 600;\n    color: #6757F1 !important;\n}\n\n.sectionLine {\n    width: 100%;\n    border-bottom: 1.5px solid #6757F1 !important;\n    display: inline-block;\n}\n\n\n.about_self, .contacts {\n    font-size: 0.8rem;\n}\n\n.jobTitle {\n    font-size: 0.85rem;\n    font-weight: 600;\n    color: black !important;\n}\n\n.nameCompany {\n    font-weight: 600;\n    font-size: 0.75rem;\n    color: black !important;\n}\n\n.experience {\n    font-size: 0.7rem;\n    text-align: right;\n}\n\n.text {\n    font-size: 0.688rem;\n    color: black !important;\n}\n\n.list {\n    padding: 0;\n    list-style-type: none;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-wrap: wrap;\n    row-gap: 10px;\n    column-gap: 10px;\n}\n\n.education, .item {\n    font-size: 0.688rem;\n    font-weight: 600;\n}\n\n.item {\n    padding: 0 15px;\n    border-right: 1px solid black;\n}\n\n.item:last-child {\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `styles_section__ojU+E`,
	"sectionLine": `styles_sectionLine__LtKUb`,
	"about_self": `styles_about_self__1sQn-`,
	"contacts": `styles_contacts__vHnbi`,
	"jobTitle": `styles_jobTitle__o4Jr8`,
	"nameCompany": `styles_nameCompany__8Mt-Z`,
	"experience": `styles_experience__KcM2Z`,
	"text": `styles_text__LdXYx`,
	"list": `styles_list__9QzSK`,
	"education": `styles_education__kMK9Q`,
	"item": `styles_item__YkqNA`
};
export default ___CSS_LOADER_EXPORT___;
