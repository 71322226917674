import styles from './styles.module.css'
import { FULL_MONTHS } from '../../../constants'
export const DescriptionExperience = ({
    company_name,
    title_job,
    experience_start_year,
    experience_end_year,
    experience_start_month,
    experience_end_month,
    description_experience
}) => {

    const experienceStart = `${FULL_MONTHS[+experience_start_month-1]}, ${experience_start_year}`
    const experienceEndYear = experience_end_year === null ? '' : experience_end_year
    const experienceEndMonth = experience_end_month === null ? '' : FULL_MONTHS[+experience_end_month-1]

    return (
        <div className="row gy-1 mb-2">
            <p className={`col-4 ${styles.jobTitle}`}>{title_job}</p>
            <p className={`col-4 ${styles.nameCompany}`}>{company_name}</p>
            <p className={`col-4 ${styles.experience}`}>{`${experienceStart} -`} {experienceEndMonth ? `${experienceEndMonth}, ` : ''} {experienceEndYear ? `${experienceEndYear}` : 'По настоящeе время'}</p>
            <p className={`col-12 ${styles.text}`}>{description_experience}</p>
        </div>
    )
}