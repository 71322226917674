import { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import { COLORS, useDebounceCallback } from '../../constants'
import { ReactComponent as BaselinePlace } from '../../icons/baseline-place.svg'
import { ReactComponent as DefaultAvatar } from '../../icons/default-avatar.svg'
import { ReactComponent as DeleteOutline } from '../../icons/delete-outline.svg'
import { ReactComponent as PlusSvg } from '../../icons/plus_circle.svg'
import { ReactComponent as TwoCoins } from '../../icons/two-coins.svg'
import { ContactMenuSelect } from '../ContactMenuSelect/ContactMenuSelect'
import { SaveResumeConstructorButton } from '../SaveResumeConstructorButton/SaveResumeConstructorButton'
import InputAutosize from '../ui/InputAutosize'
import TextareaAutosize from '../ui/TextareaAutosize'
import {
	createCertificate,
	createEducation,
	createExperience,
	createLanguage,
	createSkill,
	deleteCertificate,
	deleteEducation,
	deleteExperience,
	deleteLanguage,
	deleteSkill,
	editCertificate,
	editEducation,
	editExperience,
	editLanguage,
	updatePersonalDetails,
	updateResume,
	updateServerCertificateInner,
	updateServerEducationInner,
	updateServerExperienceInner,
	updateServerLanguageInner,
	updateServerPersonalDetailsInner,
	updateServerResumeInner,
	updateSkill,
	uploadAvatar,
} from './api'
import './styles.css'

export default function ConstructorResumeBlock({ user, resume, avatarUrl }) {
	const dispatch = useDispatch()
	const [state, setState] = useState({
		errorMsg: '',
		avatarUrl: avatarUrl,
	})
	const updateServerResume = useDebounceCallback(updateServerResumeInner, 500)
	const updateServerExperience = useDebounceCallback(
		updateServerExperienceInner,
		500
	)
	const updateServerEducation = useDebounceCallback(
		updateServerEducationInner,
		500
	)
	const updateServerCertificate = useDebounceCallback(
		updateServerCertificateInner,
		500
	)
	const updateServerLanguage = useDebounceCallback(
		updateServerLanguageInner,
		500
	)
	const updateServerPD = useDebounceCallback(
		updateServerPersonalDetailsInner,
		500
	)

	useEffect(() => {
		setState(p => ({ ...p, avatarUrl }))
	}, [avatarUrl])

	useEffect(() => console.log(resume), [resume])

	return (
		<>
			<div className='row gap-2'>
				{/* Personal Details Block */}
				<div className='w-auto position-relative'>
					{state.avatarUrl ? (
						<img
							src={state.avatarUrl}
							alt='avatar'
							className='rounded-2'
							style={{
								width: '110px',
								height: '110px',
								boxShadow: '0px 0px 12px 0px rgba(103, 87, 241, 0.30)',
							}}
						/>
					) : (
						<DefaultAvatar />
					)}
					<input
						type='file'
						accept='image/png,image/jpeg,image/webp'
						className='position-absolute opacity-0 top-0 start-0'
						style={{ cursor: 'pointer', width: '110px', height: '110px' }}
						onInput={e => {
							uploadAvatar(
								user.access_token,
								resume.id,
								e.target.files[0],
								setState
							)
						}}
					/>
				</div>
				<div className='col d-flex flex-column'>
					<span>
						<InputAutosize
							type='text'
							className='fs-4 fw-semibold no_outline'
							placeholder='Имя'
							minWidth='1rem'
							defaultWidth='3.25rem'
							value={user.personalDetails.firstName}
							onChange={e => {
								updatePersonalDetails(
									user.access_token,
									user.personalDetails,
									{ firstName: e.target.value },
									dispatch,
									updateServerPD
								)
							}}
						/>
						&nbsp;
						<InputAutosize
							type='text'
							className='fs-4 fw-semibold no_outline'
							placeholder='Фамилия'
							minWidth='1rem'
							defaultWidth='7.25rem'
							value={user.personalDetails.lastName}
							onChange={e => {
								updatePersonalDetails(
									user.access_token,
									user.personalDetails,
									{ lastName: e.target.value },
									dispatch,
									updateServerPD
								)
							}}
						/>
					</span>
					<InputAutosize
						type='text'
						className='fs-5 fw-semibold header_input'
						placeholder='Специальность'
						minWidth='1ch'
						defaultWidth='13ch'
						value={resume.title}
						onChange={e => {
							updateResume(
								user.access_token,
								resume,
								{ title: e.target.value },
								dispatch,
								updateServerResume
							)
						}}
					/>
					<ExtraInfo
						resume={resume}
						display='d-sm-none d-flex mt-2'
						updateServerResume={updateServerResume}
						dispatch={dispatch}
						user={user}
					/>
				</div>
				<div
					className='d-flex flex-row justify-content-end'
					style={{ width: 'fit-content' }}
				>
					<ContactMenuSelect user={user} resume={resume} />
				</div>
			</div>
			<ExtraInfo
				resume={resume}
				display='d-sm-flex d-none bg-info'
				updateServerResume={updateServerResume}
				dispatch={dispatch}
				user={user}
			/>
			{/* Skills Block */}
			{(resume.skills || []).length ? (
				<div className='row'>
					<span className='fs-4 fw-semibold'>
						Навыки
						<span>
							<PlusSvg
								style={{ cursor: 'pointer' }}
								className='ms-2'
								onClick={() =>
									createSkill(
										user.access_token,
										resume,
										dispatch,
										updateServerResume
									)
								}
							/>
						</span>
					</span>
					<div className='d-flex flex-row flex-wrap gap-3 pt-2'>
						{(resume.skills || []).map((s, i) => (
							<Skill
								key={i}
								i={i}
								s={s}
								dispatch={dispatch}
								user={user}
								resume={resume}
								updateServerResume={updateServerResume}
							/>
						))}
					</div>
				</div>
			) : (
				''
			)}
			{/* Experience Block */}
			{(resume.experience || []).length ? (
				<div className='row gap-3'>
					<span className='fs-4 fw-semibold'>
						Опыт работы
						<span>
							<PlusSvg
								style={{ cursor: 'pointer' }}
								className='ms-2'
								onClick={() =>
									createExperience(user.access_token, resume, dispatch)
								}
							/>
						</span>
					</span>
					{(resume.experience || []).map(exp => (
						<Experience
							key={exp.id}
							exp={exp}
							dispatch={dispatch}
							user={user}
							resume={resume}
							updateServerExperience={updateServerExperience}
						/>
					))}
				</div>
			) : (
				''
			)}
			{/* Education Block */}
			{(resume.education || []).length ? (
				<div className='row gap-3'>
					<span className='fs-4 fw-semibold'>
						Образование
						<span>
							<PlusSvg
								style={{ cursor: 'pointer' }}
								className='ms-2'
								onClick={() =>
									createEducation(user.access_token, resume, dispatch)
								}
							/>
						</span>
					</span>
					{(resume.education || []).map(edu => (
						<Education
							key={edu.id}
							edu={edu}
							dispatch={dispatch}
							user={user}
							resume={resume}
							updateServerEducation={updateServerEducation}
						/>
					))}
				</div>
			) : (
				''
			)}
			{/* Certificates Block */}
			{(resume.certificates || []).length ? (
				<div className='row gap-3'>
					<span className='fs-4 fw-semibold'>
						Курсы и сертификаты
						<span>
							<PlusSvg
								style={{ cursor: 'pointer' }}
								className='ms-2'
								onClick={() =>
									createCertificate(user.access_token, resume, dispatch)
								}
							/>
						</span>
					</span>
					{(resume.certificates || []).map(cert => (
						<Certificate
							key={cert.id}
							cert={cert}
							user={user}
							resume={resume}
							dispatch={dispatch}
							updateServerCertificate={updateServerCertificate}
						/>
					))}
				</div>
			) : (
				''
			)}
			{/* Languages Block */}
			{(resume.languages || []).length ? (
				<div className='row'>
					<span className='fs-4 mb-3 fw-semibold'>
						Языки
						<span>
							<PlusSvg
								style={{ cursor: 'pointer' }}
								className='ms-2'
								onClick={() =>
									createLanguage(user.access_token, resume, dispatch)
								}
							/>
						</span>
					</span>
					<div className='d-flex flex-row flex-wrap gap-3'>
						{(resume.languages || []).map((lang, i) => (
							<Language
								key={lang.id}
								user={user}
								resume={resume}
								lang={lang}
								updateServerLanguage={updateServerLanguage}
								dispatch={dispatch}
							/>
						))}
					</div>
				</div>
			) : (
				''
			)}
			{/* Resume controls */}
			<div className='row gap-2 gap-sm-4'>
				<SaveResumeConstructorButton resume={resume} user={user} />
			</div>
		</>
	)
}

const ExtraInfo = ({ display, resume, dispatch, updateServerResume, user }) => {
	return (
		<div className={'d-flex flex-row flex-sm-wrap gap-2 gap-sm-4' + display}>
			<div className='d-flex flex-row gap-2'>
				<BaselinePlace />
				<InputAutosize
					type='text'
					className='fs-6 fw-semibold header_input'
					placeholder='Город'
					minWidth='1ch'
					defaultWidth='5ch'
					value={resume.city}
					onChange={e => {
						updateResume(
							user.access_token,
							resume,
							{ city: e.target.value },
							dispatch,
							updateServerResume
						)
					}}
				/>
			</div>
			<div className='d-flex flex-row gap-2'>
				<TwoCoins />
				<InputAutosize
					type='text'
					className='fs-6 fw-semibold header_input'
					placeholder='з/п'
					minWidth='1ch'
					defaultWidth='2.5ch'
					value={
						resume.desiredSalary ? resume.desiredSalary.toLocaleString() : null
					}
					onChange={e => {
						const val = e.target.value ? +e.target.value.replace(/\D/g, '') : 0
						updateResume(
							user.access_token,
							resume,
							{ desiredSalary: val, desiredSalaryCurrency: 'RUB' },
							dispatch,
							updateServerResume
						)
					}}
				/>
				<span
					className='fs-6 fw-semibold'
					style={{ color: COLORS.header_text, marginLeft: '-4px' }}
				>
					руб/мес
				</span>
			</div>
		</div>
	)
}

const Skill = ({ s, updateServerResume, user, resume, dispatch, i }) => {
	return (
		<span className='delete-parent delete-not-mobile'>
			<button
				className='rounded-start py-2'
				style={{ top: '5px', backgroundColor: COLORS.light_neutral }}
				onClick={() =>
					deleteSkill(
						user.access_token,
						resume,
						i,
						dispatch,
						updateServerResume
					)
				}
			>
				<DeleteOutline fill='black' />
			</button>
			<InputAutosize
				type='text'
				className='fs-6 fw-medium px-3 py-2 rounded-end no_outline'
				style={{ backgroundColor: COLORS.light_neutral, marginLeft: '1.5px' }}
				placeholder='Навык'
				minWidth='4ch'
				defaultWidth='5.25rem'
				value={s}
				onChange={e => {
					updateSkill(
						user.access_token,
						resume,
						i,
						e.target.value,
						dispatch,
						updateServerResume
					)
				}}
			/>
		</span>
	)
}

const DeleteButton = ({ del, id, user, obj, dispatch }) => {
	return (
		<div className='col-auto pe-0'>
			<button
				className='rounded-1 p-1'
				style={{ top: '-2px', backgroundColor: COLORS.light_neutral }}
				onClick={() => del(user.access_token, id, obj, dispatch)}
			>
				<DeleteOutline />
			</button>
		</div>
	)
}

const Experience = ({
	exp,
	user,
	resume,
	dispatch,
	updateServerExperience,
}) => {
	return (
		<div className='row delete-parent'>
			<DeleteButton
				del={deleteExperience}
				id={exp.id}
				obj={resume.experience}
				user={user}
				dispatch={dispatch}
			/>
			{/* <div className="col-auto pe-0">
        <button className=" p-1" style={{top: "-2px", backgroundColor: COLORS.light_neutral}} 
                onClick={() =>  deleteExperience(user.access_token, exp.id, resume.experience, dispatch)}>
            <DeleteOutline />
        </button>
    </div> */}
			<div className='col col-sm-5 col-md-4 col-lg-5 col-xl-4 d-flex flex-column'>
				<input
					className='fs-6 fw-medium mb-1 no_outline '
					style={{ color: COLORS.primary }}
					value={exp.company}
					onChange={ev =>
						editExperience(
							user.access_token,
							exp.id,
							{ company: ev.target.value },
							resume.experience,
							dispatch,
							updateServerExperience
						)
					}
					placeholder='Компания'
				/>
				<input
					className='fs-6 fw-medium no_outline'
					value={exp.position}
					onChange={ev =>
						editExperience(
							user.access_token,
							exp.id,
							{ position: ev.target.value },
							resume.experience,
							dispatch,
							updateServerExperience
						)
					}
					placeholder='Позиция'
				/>
				<span>
					<InputMask
						className='fs-6 fw-medium no_outline m-0 p-0'
						style={{ width: '3.5rem' }}
						mask='99.9999'
						placeholder='мм.гггг'
						value={(exp.startMonth || '') + '.' + (exp.startYear || '')}
						onChange={ev => {
							let value = ev.target.value
							if (value[0] !== '1' && value[0] !== '0') {
								value = '0'.concat(value.slice(1))
							}
							editExperience(
								user.access_token,
								exp.id,
								{
									startMonth: (ev.target.value.split('.')[0] || '').replaceAll(
										'_',
										''
									),
									startYear: (ev.target.value.split('.')[1] || '').replaceAll(
										'_',
										''
									),
								},
								resume.experience,
								dispatch,
								updateServerExperience
							)
						}}
					/>
					&nbsp;-&nbsp;
					<InputMask
						className='fs-6 fw-medium no_outline m-0 p-0'
						style={{ width: '3.75rem' }}
						mask='99.9999'
						placeholder='мм.гггг'
						value={(exp.endMonth || '') + '.' + (exp.endYear || '')}
						onChange={ev => {
							let value = ev.target.value
							if (value[0] !== '1' && value[0] !== '0') {
								value = '0'.concat(value.slice(1))
							}
							editExperience(
								user.access_token,
								exp.id,
								{
									endMonth: (ev.target.value.split('.')[0] || '').replaceAll(
										'_',
										''
									),
									endYear: (ev.target.value.split('.')[1] || '').replaceAll(
										'_',
										''
									),
								},
								resume.experience,
								dispatch,
								updateServerExperience
							)
						}}
					/>
				</span>
			</div>
			<div className='col'>
				<TextareaAutosize
					className='fs-6 fw-medium no_outline w-100'
					value={exp.description}
					onChange={ev =>
						editExperience(
							user.access_token,
							exp.id,
							{ description: ev.target.value },
							resume.experience,
							dispatch,
							updateServerExperience
						)
					}
					placeholder='Описание'
					minHeight='1.5rem'
					defaultHeight='1.5rem'
					style={{ resize: 'none', minWidth: '100px' }}
				/>
			</div>
		</div>
	)
}

const Education = ({ user, resume, dispatch, updateServerEducation, edu }) => {
	return (
		<div className='row delete-parent flex-wrap'>
			<DeleteButton
				del={deleteEducation}
				id={edu.id}
				obj={resume.education}
				user={user}
				dispatch={dispatch}
			/>
			<div className='col col-md-6 d-flex flex-column'>
				<input
					className='fs-6 fw-medium no_outline delete-moveable'
					style={{ color: COLORS.primary }}
					value={edu.name}
					onChange={ev =>
						editEducation(
							user.access_token,
							edu.id,
							{ name: ev.target.value },
							resume.education,
							dispatch,
							updateServerEducation
						)
					}
					placeholder='Учебное заведение'
				/>
				<span className='fs-6 fw-medium'>
					<InputAutosize
						className='fs-6 fw-medium no_outline'
						value={edu.degree}
						onChange={ev =>
							editEducation(
								user.access_token,
								edu.id,
								{ degree: ev.target.value },
								resume.education,
								dispatch,
								updateServerEducation
							)
						}
						placeholder='Степень'
						minWidth='1ch'
						defaultWidth='7ch'
					/>
					,&nbsp;
					<InputMask
						className='fs-6 fw-medium no_outline m-0 p-0'
						style={{ width: '2.5rem' }}
						mask='9999'
						placeholder='гггг'
						value={edu.startYear}
						onChange={ev =>
							editEducation(
								user.access_token,
								edu.id,
								{ startYear: (ev.target.value || '').replaceAll('_', '') },
								resume.education,
								dispatch,
								updateServerEducation
							)
						}
					/>
					-&nbsp;
					<InputMask
						className='fs-6 fw-medium no_outline m-0 p-0'
						style={{ width: '2.5rem' }}
						mask='9999'
						placeholder='гггг'
						value={edu.endYear}
						onChange={ev =>
							editEducation(
								user.access_token,
								edu.id,
								{ endYear: (ev.target.value || '').replaceAll('_', '') },
								resume.education,
								dispatch,
								updateServerEducation
							)
						}
					/>
				</span>
			</div>
			<div className='col text-nowrap'>
				<TextareaAutosize
					className='fs-6 fw-medium no_outline w-100'
					value={edu.description}
					onChange={ev =>
						editEducation(
							user.access_token,
							edu.id,
							{ description: ev.target.value },
							resume.education,
							dispatch,
							updateServerEducation
						)
					}
					placeholder='Описание'
					minHeight='1.5rem'
					defaultHeight='1.5rem'
					style={{ resize: 'none', minWidth: '100px' }}
				/>
			</div>
		</div>
	)
}

const Certificate = ({
	cert,
	updateServerCertificate,
	user,
	resume,
	dispatch,
}) => {
	return (
		<div className='row delete-parent'>
			<DeleteButton
				del={deleteCertificate}
				id={cert.id}
				obj={resume.certificates}
				user={user}
				dispatch={dispatch}
			/>
			{/* <button className="delete-btn p-0" style={{top: "-2px", left: "20px"}}
            onClick={() =>  deleteCertificate(user.access_token, cert.id, resume.certificates, dispatch)}>
        <DeleteOutline />
    </button> */}
			<div className='col d-flex flex-column col-sm-5 col-md-4 col-lg-5 col-xl-4'>
				<input
					className='fs-6 fw-medium no_outline delete-moveable'
					style={{ color: COLORS.primary }}
					value={cert.name}
					onChange={ev =>
						editCertificate(
							user.access_token,
							cert.id,
							{ name: ev.target.value },
							resume.certificates,
							dispatch,
							updateServerCertificate
						)
					}
					placeholder='Название учреждения'
				/>
				<InputMask
					className='fs-6 fw-medium no_outline m-0 p-0'
					style={{ width: '2.85rem' }}
					mask='9999'
					placeholder='гггг'
					value={cert.year}
					onChange={ev =>
						editCertificate(
							user.access_token,
							cert.id,
							{ year: (ev.target.value || '').replaceAll('_', '') },
							resume.certificates,
							dispatch,
							updateServerCertificate
						)
					}
				/>
			</div>
			<div className='col'>
				<TextareaAutosize
					className='fs-6 fw-medium no_outline w-100'
					value={cert.description}
					onChange={ev =>
						editCertificate(
							user.access_token,
							cert.id,
							{ description: ev.target.value },
							resume.certificates,
							dispatch,
							updateServerCertificate
						)
					}
					placeholder='Описание'
					minHeight='1.5rem'
					defaultHeight='1.5rem'
					style={{ resize: 'none', minWidth: '100px' }}
				/>
			</div>
		</div>
	)
}

const Language = ({ lang, user, resume, updateServerLanguage, dispatch }) => {
	return (
		<div className='d-flex flex-row delete-parent delete-not-mobile'>
			<button
				className='rounded-start-1'
				style={{
					top: '-3px',
					backgroundColor: COLORS.light_neutral,
					marginRight: '1px',
				}}
				onClick={() =>
					deleteLanguage(user.access_token, lang.id, resume.languages, dispatch)
				}
			>
				<DeleteOutline />
			</button>
			<span
				className='fs-6 fw-medium px-3 py-2 rounded-end-1 delete-moveable'
				style={{ backgroundColor: COLORS.light_neutral }}
			>
				<InputAutosize
					className='fs-6 fw-medium no_outline'
					value={lang.name}
					onChange={ev =>
						editLanguage(
							user.access_token,
							lang.id,
							{ name: ev.target.value },
							resume.languages,
							dispatch,
							updateServerLanguage
						)
					}
					placeholder='Язык'
					minWidth='1ch'
					defaultWidth='4ch'
				/>
				&nbsp;-&nbsp;
				<input
					type='text'
					className='fs-6 fw-medium no_outline'
					style={{ color: COLORS.primary, width: '3.5ch' }}
					value={lang.level}
					onChange={ev =>
						editLanguage(
							user.access_token,
							lang.id,
							{ level: ev.target.value },
							resume.languages,
							dispatch,
							updateServerLanguage
						)
					}
					placeholder='A1'
				/>
			</span>
		</div>
	)
}
