import styles from './styles.module.css';
import html2pdf from 'html2pdf.js'
import { renderToString } from 'react-dom/server';
import { useState } from 'react';
import { PageOne } from '../PdfResumePages/pageOne/PageOne';
import { PageTwo } from '../PdfResumePages/pageTwo/PageTwo';
import { PageThree } from '../PdfResumePages/pageThree/PageThree';
import {COLORS} from "../../constants";

export const SaveResumeConstructorButton = ({resume, user}) => {
    const [visible, setVisible] = useState(false);

    function openDropdownClickHandler() {
        setVisible(true)
    }

    function closeDropdownClickHandler() {
        setVisible(false)
    }

    function downloadPdfClickHandler(pageDownload) {
        const elem = renderToString(pageDownload);
        html2pdf().from(elem).set({filename: `${user.personalDetails.firstName} ${user.personalDetails.lastName}.pdf`}).save()
    }


    return (
        <div className={`col-12 col-sm ${styles.dropdown}`}>
            <button className="w-100 px-3 py-2 rounded-1 fs-6" style={{backgroundColor: COLORS.neutral}}
                    onClick={openDropdownClickHandler}>Скачать резюме</button>
            {visible &&
                <ul className={styles.list}>
                    <li
                        className={`btn w-60 ${styles.item}`}
                        onClick={() =>
                            downloadPdfClickHandler(<PageOne
                                props={resume}
                                id={resume.id}
                                user={user}/>)
                        }>
                        <span>Простой</span>
                    </li>
                    <li
                        className={`btn w-60 ${styles.item}`}
                        onClick={() =>
                            downloadPdfClickHandler(<PageTwo
                                props={resume}
                                id={resume.id}
                                user={user}
                            />)}>
                        <span>Современный</span>
                    </li>
                    <li
                        className={`btn w-60 ${styles.item}`}
                        onClick={() => downloadPdfClickHandler(<PageThree
                            props={resume}
                            id={resume.id}
                            user={user}
                        />)}>
                        <span>Профессиональный</span>
                    </li>
                    <button
                        className={`btn-close ${styles.closeBtn}`}
                        type="button"
                        aria-label="Close"
                        onClick={closeDropdownClickHandler}
                    ></button>
                </ul>}
        </div>
    )
}