import {COLORS, CONSOLE_MESSAGES_ERROR, ErrorMsg, SOURCE, USER_MESSAGES_ERROR} from "../../constants";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as MockupBlocksSvg} from "../../icons/main_landing/mockup-blocks.svg";
import {ReactComponent as ContactArrowSvg} from "../../icons/main_landing/contact-arrow.svg";

import {ReactComponent as ArrowRightSvg} from "../../icons/main_landing/arrow-right.svg";
import {ReactComponent as CardStep1IconSvg} from "../../icons/main_landing/card-step-1-icon.svg";
import {ReactComponent as CardStep2IconSvg} from "../../icons/main_landing/card-step-2-icon.svg";
import {ReactComponent as CardStep3IconSvg} from "../../icons/main_landing/card-step-3-icon.svg";
import {ReactComponent as CheckMarkSvg} from "../../icons/main_landing/check-marks.svg";
import CroppedMockupsImg from "../../icons/main_landing/cropped-mockups.png";
import CardStep1Img from "../../icons/main_landing/card-step-1.png";
import CardStep2Img from "../../icons/main_landing/card-step-2.png";
import CardStep3Img from "../../icons/main_landing/card-step-3.png";
import "./styles.css";
import {Nav, Navbar} from "react-bootstrap";
import {useNavigate} from "react-router";
import {Loader} from "../../components/Loader";
import {refreshPersonalDetails} from "../Auth";

export const MainLanding = () => {
    const [state, setState] = useState({
        errorMsg: "",
        hhLoginUrl: ""
    });
    const user = useSelector(state => state.userSlice.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user.access_token) fetch(`${SOURCE.url}/api/v1/auth/hhAuthorizeUrl`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => res.json())
            .then(res => setState(state => ({...state, hhLoginUrl: res})))
            .catch(e => {
                setState(state => ({...state, errorMsg: USER_MESSAGES_ERROR.AUTH}))
                console.error(CONSOLE_MESSAGES_ERROR.AUTH, e)
            });
        else if (user.id) refreshPersonalDetails(user, dispatch, setState, navigate);
    }, []);

    return <div className="w-100 d-flex flex-column pt-3">
        {state.errorMsg && <ErrorMsg state={state} setState={setState}/>}

        <span className="slogan text-end text-xl-start w-100 fw-bold" style={{color: COLORS.orange}}>Впечатляющее резюме</span>
        <div className="row p-0 m-0 gap-3">
            <div className="d-flex flex-column m-0 p-0 col-12 col-xl flex-1">
                <span className="slogan w-100 text-end fw-bold" style={{color: COLORS.orange}}>за 5 минут</span>
                <span className="slogan w-100 fw-bold" style={{color: COLORS.dark_primary}}>Бесплатно</span>
                <div className="d-none d-xl-flex flex-grow-1"></div>
                <div className="d-none d-xl-flex flex-column" style={{
                    padding: "4rem",
                    backgroundColor: COLORS.orange,
                    color: "#fff",
                    borderRadius: "5rem",
                    height: "65%"
                }}>
                    <span className="fw-bold" style={{fontSize: "min(4vw, 4rem)"}}>Ваше резюме точно заметят</span>
                    <div className="flex-grow-1"></div>
                    <span style={{fontSize: "min(2vw, 2.25rem)"}}>Наши шаблоны одобрены рекрутерами крупнейших российских компаний</span>
                </div>
            </div>
            <div className="mask-blocks m-0 p-0 position-relative col-12 col-xl">
                <div className="card-1 d-flex flex-column gap-2 position-absolute top-0 start-0 rounded-5"
                     style={{backgroundColor: "#E9F0F6", zIndex: 3, width: "53%", minHeight: "33%"}}>
                    <span className="card-heading-1 fw-bold" style={{color: COLORS.dark_primary}}>Простая регистрация</span>
                    <span className="card-heading-2 fw-medium" style={{color: COLORS.dark_primary}}>Достаточно войти через hh.ru</span>
                    <div className="flex-grow-1"></div>
                    <button className="card-btn-1 card-heading-2 text-white fw-bold" style={{
                        backgroundColor: COLORS.orange,
                        width: "fit-content",
                        borderRadius: "1.5rem",
                    }} onClick={() => {
                        if (user.access_token) navigate("/select_resume")
                        else window.location.href = state.hhLoginUrl
                    }}>Войти через hh.ru</button>
                </div>
                <MockupBlocksSvg className="position-absolute top-0 end-0 w-100 h-100" style={{zIndex: 1}} />
                <img src={CroppedMockupsImg} alt="mockup" className="position-absolute top-0 end-0 w-100 h-100" style={{zIndex: 2}} />
                <div className="card-1 d-flex flex-column gap-1 position-absolute bottom-0 end-0 rounded-5"
                     style={{backgroundColor: "#E9F0F6", zIndex: 3, width: "35.5%", minHeight: "36.5%"}}>
                    <span className="card-heading-2 fw-bold" style={{color: COLORS.dark_primary}}>Возник вопрос?</span>
                    <span className="card-heading-3 fw-medium" style={{color: COLORS.dark_primary}}>Тогда свяжись с нами и мы постараемся его решить!</span>
                    <div className="flex-grow-1"></div>
                    <button className="bg-transparent align-self-end" style={{width: "fit-content"}}>
                        <ContactArrowSvg style={{width: "min(3rem, 3vw)"}} />
                    </button>
                </div>
            </div>
        </div>
        <div className="d-flex flex-row mt-3 p-3 p-md-5 text-white align-items-center" style={{backgroundColor: COLORS.dark_primary, borderRadius: "3.125rem"}}>
            <span className="card-heading-2">Также мы проведем интервью и поможем тебе найти работу</span>
            <div className="flex-grow-1"></div>
            <a href="https://hurtle.ru" className="text-decoration-none">
                <span className="card-heading-2 fw-semibold text-white d-none d-xl-inline">Подробнее</span>
                <ArrowRightSvg className="ms-2" style={{height: "min(2vh, .875rem)"}} />
            </a>
        </div>
        <span className="fw-bold w-100 text-center text-adaptive-40" style={{marginTop: "min(12rem, 10vw)", color: COLORS.orange}}>Это сложно?</span>
        <div className="row p-0 mx-0 gap-3" style={{marginTop: "min(4rem, 2vw)", marginBottom: "min(12rem, 10vw)"}}>
            <div className="steps-card col-12 col-xl d-flex align-items-center" style={{backgroundColor: COLORS.dark_primary}}>
                <img src={CardStep1Img} alt="card-step-1" className="" />
                <div className="d-flex flex-column w-100 pe-5 position-relative">
                    <span className="text-white card-heading-1 fw-semibold">1. Регистрация</span>
                    <span className="card-heading-2" style={{color: "#938CA9"}}>Просто войди через свой аккаунт на hh.ru</span>
                    <CardStep1IconSvg className="position-absolute top-0 end-0" style={{width: "8%"}} />
                </div>
            </div>
            <div className="steps-card col-12 col-xl d-flex align-items-center" style={{backgroundColor: COLORS.dark_primary}}>
                <img src={CardStep2Img} alt="card-step-1" className="" />
                <div className="d-flex flex-column w-100 pe-5 position-relative">
                    <span className="text-white card-heading-1 fw-semibold">2. Выбери шаблон</span>
                    <span className="card-heading-2" style={{color: "#938CA9"}}>Выбери любой из трёх шаблонов и настрой его</span>
                    <CardStep2IconSvg className="position-absolute top-0 end-0" style={{width: "8%"}} />
                </div>
            </div>
            <div className="steps-card col-12 col-xl d-flex align-items-center" style={{backgroundColor: COLORS.dark_primary}}>
                <img src={CardStep3Img} alt="card-step-1" className="" />
                <div className="d-flex flex-column w-100 pe-5 position-relative">
                    <span className="text-white card-heading-1 fw-semibold">3. Скачай PDF</span>
                    <span className="card-heading-2" style={{color: "#938CA9"}}>Готово! Остаётся просто скачать готовый файл</span>
                    <CardStep3IconSvg className="position-absolute top-0 end-0" style={{width: "8%"}} />
                </div>
            </div>
        </div>
        <div className="position-relative d-flex flex-row flex-wrap gap-4 gap-xl-5 justify-content-xl-between" style={{paddingTop: "min(12rem, 10vw)", paddingBottom: "min(12rem, 10vw)"}}>
            <div className="position-absolute top-0" style={{
                width: "100vw",
                height: "calc(100% + 5rem)",
                left: "calc(-50vw + 50%)",
                borderRadius: "3.125rem 3.125rem 0 0",
                backgroundColor: COLORS.orange,
                zIndex: -1
            }}></div>
            <div className="col-xl-5 col-12 text-white" style={{gap: "2.25rem"}}>
                <span className="fw-bold" style={{fontSize: "min(4rem, 4vw)"}}>Сделано профессионалами</span> <br/>
                <div className="pe-xl-5" style={{fontSize: "min(1.5rem, 3vw)"}}>Этот сервис создан совместно с опытными <span className="text-yellow-highlight">HR-специалистами</span> и одобрен рекрутерами крупнейших российских компаний. Каждый шаблон прошел не один десяток проверок, так что ты можешь быть уверен в <span className="text-yellow-highlight">надежности</span> твоего резюме.</div>
            </div>
            <div className="col-xl-6 col-12 d-flex flex-column gap-2 gap-xl-3 flex-1 justify-content-center">
                <div className="w-100 bg-white d-flex flex-row align-items-center text-center py-3 py-xl-4" style={{borderRadius: "3.125rem"}}>
                    <span className="w-100 fw-bold" style={{color: COLORS.orange, fontSize: "min(2rem, 3vw)"}}>Без водяных знаков</span>
                </div>
                <div className="w-100 bg-white d-flex flex-row align-items-center text-center py-3 py-xl-4" style={{borderRadius: "3.125rem"}}>
                    <span className="w-100 fw-bold" style={{color: COLORS.orange, fontSize: "min(2rem, 3vw)"}}>Полностью кастомизируемый</span>
                </div>
                <div className="w-100 bg-white d-flex flex-row align-items-center text-center py-3 py-xl-4" style={{borderRadius: "3.125rem"}}>
                    <span className="w-100 fw-bold" style={{color: COLORS.orange_primary, fontSize: "min(2rem, 3vw)"}}>Абсолютно бесплатный</span>
                </div>
            </div>
        </div>
        <div className="position-relative d-flex flex-column" style={{padding: "min(6rem, 5vw) 0"}}>
            <div className="position-absolute bg-white top-0" id="orange-bg" style={{
                width: "100vw",
                height: "calc(100% + 5rem)",
                left: "calc(-50vw + 50%)",
                borderRadius: "3.125rem",
                zIndex: -1,
            }}></div>
            <span className="fw-bold w-100 text-center text-adaptive-40" style={{color: COLORS.orange}}>Шаблоны для успеха</span>
            <span className="text-adaptive-15 w-100 text-center" style={{color: "#938CA9"}}>Выбирай один из трех шаблонов резюме, созданных специально для <b>хартл</b></span>
            <div className="row gap-3 mx-0" style={{paddingTop: "min(6rem, 5vw)"}}>
                <div className="col nav flex-column nav-pills order-first align-items-start gap-2"
                    id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <button className="nav-link active btn fs-1 p-0 mt-4 red_primary"
                            style={{background: 'none', width: 'fit-content', fontWeight: 'bold'}}
                            id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                            role="tab" aria-controls="v-pills-home" aria-selected="true">Простой
                    </button>
                    <button className="nav-link btn fs-1 p-0 red_primary"
                            style={{background: 'none', width: 'fit-content', fontWeight: 'bold'}}
                            id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile"
                            type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Современный
                    </button>
                    <button className="nav-link btn fs-1 p-0 red_primary"
                            style={{background: 'none', width: 'fit-content', fontWeight: 'bold'}}
                            id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages"
                            type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        Профессиональный
                    </button>
                    <div className="flex-grow-1"></div>
                    <button className="fs-5 fw-bold rounded-4 px-4 py-2 bg-transparent" onClick={() => {
                        if (user.access_token) navigate("/select_resume")
                        else window.location.href = state.hhLoginUrl
                    }}
                            style={{color: COLORS.red_primary, border: "3px solid var(--red-primary)"}}>Сделать резюме мечты</button>
                </div>
                <div className="col-lg tab-content order-lg-last mb-4" id="v-pills-tabContent">
                    <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                         aria-labelledby="v-pills-home-tab">
                        <img src={`${SOURCE.source_url}/r_tmp_full.jpg`}
                             style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                             className="img-fluid d-none d-md-block"
                             alt="r_tmp_full"/>
                        <img src={`${SOURCE.source_url}/r_tmp_small.jpg`}
                             style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                             className="d-block d-md-none img-fluid"
                             alt="r_tmp_small"/>
                    </div>
                    <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
                         aria-labelledby="v-pills-profile-tab">
                        <img src={`${SOURCE.source_url}/r_tmp_full-1.jpg`}
                             style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                             className="img-fluid d-none d-md-block"
                             alt="r_tmp_full-1"/>
                        <img src={`${SOURCE.source_url}/r_tmp_small-1.jpg`}
                             style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03)) '}}
                             className="d-block d-md-none img-fluid"
                             alt="r_tmp_small-1"/>
                    </div>
                    <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
                         aria-labelledby="v-pills-messages-tab">
                        <img src={`${SOURCE.source_url}/r_tmp_full-2.jpg`}
                             style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                             className="img-fluid d-none d-md-block"
                             alt="r_tmp_full-2"/>
                        <img src={`${SOURCE.source_url}/r_tmp_small-2.jpg`}
                             style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                             className="d-block d-md-none img-fluid"
                             alt="r_tmp_small-2"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="position-relative d-flex flex-column align-items-center" style={{padding: "min(6rem, 5vw) 0 min(6rem, 86vw) 0"}}>
            <div className="position-absolute top-0" id="orange-bg" style={{
                width: "100vw",
                height: "calc(100% + 5rem)",
                left: "calc(-50vw + 50%)",
                borderRadius: "3.125rem 3.125rem 0 0",
                zIndex: -1,
                backgroundColor: COLORS.dark_primary
            }}></div>
            <span className="fw-bold w-100 text-center text-white text-adaptive-40">Бесплатно. Точно бесплатно</span>
            <span className="text-adaptive-15 w-100 text-center mb-2" style={{color: "#756E68"}}>Резюме обязано быть качественным и это не должно стоить денег</span>
            <div className="position-relative px-4 px-xl-5 py-3 py-xl-4 mt-4 mt-xl-5 rounded-5 text-white d-flex flex-column" style={{
                backgroundColor: COLORS.orange,
                width: "min(100%, 32rem)",
                aspectRatio: "0.843/1"
            }}>
                <span className="text-adaptive-15">Полный доступ</span>
                <span className="fw-bold" style={{fontSize: "min(3rem, 7vw)"}}>0 руб. Навсегда</span>
                <div className="d-flex flex-column flex-grow-1 justify-content-center gap-3">
                    <div className="d-flex flex-row gap-1 align-items-center" style={{marginTop: "max(-4.5rem, -11vw)"}}>
                        <CheckMarkSvg className="" style={{width: "6%"}} />
                        <span className="ms-2 text-adaptive-15">импорт из hh.ru</span>
                    </div>
                    <div className="d-flex flex-row gap-1 align-items-center">
                        <CheckMarkSvg className="" style={{width: "6%"}} />
                        <span className="ms-2 text-adaptive-15">настройка шаблона</span>
                    </div>
                    <div className="d-flex flex-row gap-1 align-items-center">
                        <CheckMarkSvg className="" style={{width: "6%"}} />
                        <span className="ms-2 text-adaptive-15">скачивание PDF файла</span>
                    </div>
                    <div className="d-flex flex-row gap-1 align-items-center">
                        <CheckMarkSvg className="" style={{width: "6%"}} />
                        <span className="ms-2 text-adaptive-15">без водяных знаков</span>
                    </div>
                </div>
                <button className="position-absolute bottom-0 bg-white px-4 py-3 fw-bold rounded-5" style={{
                    left: "50%",
                    translate: "-50% 50%",
                    width: "65%",
                    color: COLORS.orange,
                    fontSize: "min(1.5rem, 4vw)"
                }} onClick={() => {
                    if (user.access_token) navigate("/select_resume")
                    else window.location.href = state.hhLoginUrl
                }}>
                    Войти через hh.ru
                </button>
            </div>
        </div>
        <hr className="m-0 p-0" style={{borderColor: COLORS.header_text}}/>
    </div>
}