import {Stack} from "react-bootstrap";
import {MainHeader} from "./components/MainHeader";
import {Footer} from "./components/Footer";
import {Route, Routes, useLocation} from "react-router";
import {OldMainLanding} from './screens/OldMainLanding'
import {ResumeNotFound} from "./screens/ResumeNotFound";
import {Almost} from "./screens/Almost";
import Auth from "./screens/Auth";
import Logout from "./screens/Logout";
import SelectResume from "./screens/SelectResume";
import {MainLanding} from "./screens/MainLanding/MainLanding";
import { MAX_LANDING_WIDTH, MAX_WIDTH, PADDINGS } from "./constants";
import { Contacts } from "./screens/Contacts";
import './buttons.scss'
import ConstructorPage from "./screens/ConstructorPage/ConstructorPage";

function App() {
    const loc = useLocation();
    return (
        <Stack style={{minHeight: '100vh'}}>
            <div className={PADDINGS}>
                <div className="w-100 mx-auto" style={{maxWidth: MAX_WIDTH}}>
                    <MainHeader/>
                    <Routes>
                        <Route path="/" element={<MainLanding/>}/>
                        <Route path="/old" element={<OldMainLanding/>}/>
                        <Route path="/auth" element={<Auth/>}/>
                        <Route path="/logout" element={<Logout/>}/>
                        <Route path="/resume_not_found" element={<ResumeNotFound/>}/>
                        <Route path="/almost" element={<Almost/>}/>
                        <Route path="/contacts" element={<Contacts/>}/>
                        <Route path="/constructor" element={<ConstructorPage/>}/>
                        <Route path="/select_resume" element={<SelectResume/>}/>

                    </Routes>
                </div>
            </div>
            <Footer/>
        </Stack>
    );
}

export default App; 
