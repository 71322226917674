import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getResumeAvatar, updateUserResume} from "./utils";
import ConstructorResumeBlock from "../../components/ConstructorResumeBlock/ConstructorResumeBlock";
import ConstructorSectionsBlock from "../../components/ConstructorSectionsBlock/ConstructorSectionsBlock";
import {ReactComponent as BackArrowSvg} from "../../icons/back-arrow.svg";
import {ReactComponent as ResumeSvg} from "../../icons/resume.svg";
import {useLocation, useNavigate} from "react-router";
import {COLORS} from "../../constants";
import { Loader } from "../../components/Loader";

export default function ConstructorPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const resumeId = location.state ? location.state["resumeId"] : null;
    const user = useSelector(state => state.userSlice.user);
    const resume = useSelector(state => state.resumeSlice.resume);
    const [state, setState] = useState({
        avatarUrl: null
    });

    useEffect(() => {
        if (!user.access_token) return navigate("/");
        if (!resumeId) return navigate("/select_resume");
        updateUserResume(user.access_token, dispatch, resumeId);
        getResumeAvatar(user.access_token, resumeId).then(data => data && setState(p => ({...p, avatarUrl: URL.createObjectURL(data)})));
    }, [user.access_token]);

    return (!resume.loaded || !user.loaded) ? <Loader /> : <>
        <div className="d-flex flex-row align-items-center w-100 m-0 px-4 px-sm-5 py-4 bg-white rounded-3">
            <BackArrowSvg style={{marginRight: "2.5rem", cursor: "pointer", width: "25px"}} onClick={() => navigate("/select_resume")} />
            <div className="rounded-3 d-flex align-items-center" style={{padding: "0.75rem 0.875rem", backgroundColor: COLORS.neutral}}>
                <ResumeSvg />
                <span className="fs-6 fw-semibold ms-2">Резюме</span>
            </div>
        </div>
        <div className="row w-100 m-0 mt-4 gap-5 pb-5">
            <div className="position-fixed top-0 start-0 w-100 h-100 min-vw-100 min-vh-100" style={{backgroundColor: "#F8F7FB", zIndex: -1}}></div>
            <div className="col bg-white rounded-3 px-4 py-5 px-sm-5 d-flex flex-column gap-4 gap-sm-5"
                 style={{height: "fit-content"}}>
                <ConstructorResumeBlock user={user} resume={resume} avatarUrl={state.avatarUrl} />
            </div>
            <div className="col-lg-4 d-flex flex-column gap-4 p-0">
                <ConstructorSectionsBlock user={user} resume={resume || {}} />
            </div>
        </div>
    </>
}