import { SOURCE } from "../constants"

export const Almost = () => {
    return <div className='w-100'>
        <p className="fs-1 text-center mt-5" style={{fontWeight: '600'}}>Почти закончили!</p>
        <p className="fs-1 text-center" style={{fontWeight: '600'}}>Осталось выбрать шаблон!</p>
        <div className="row mt-5">
            <div
                className="col nav flex-column nav-pills order-lg-first order-last align-items-center align-items-md-start"
                id="v-pills-tab" role="tablist" aria-orientation="vertical">

                <button className="nav-link active btn fs-1 p-0 mb-2 mt-4 resumetitle"
                        id="v-pills-home-tab"
                        type="button"
                        name="resume_template_one"
                        style={{background: 'none', color: '#CCC7FF', width: 'fit-content', fontWeight: 'bold'}}
                        data-bs-toggle="pill" data-bs-target="#v-pills-home"
                        role="tab" aria-controls="v-pills-home" aria-selected="true">Простой
                </button>
                <button className="nav-link btn fs-1 p-0 mb-2 resumetitle"
                        disabled
                        type="button"
                        style={{background: 'none', color: '#CCC7FF', width: 'fit-content', fontWeight: 'bold'}}
                        name="resume_template_two" id="v-pills-profile-tab" data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                        aria-selected="false"
                >
                    Современный
                </button>
                <button
                    className="nav-link btn fs-1 p-0 mb-2 resumetitle"
                    id="v-pills-messages-tab"
                    disabled
                    type="button"
                    style={{background: 'none', color: '#CCC7FF', width: 'fit-content', fontWeight: 'bold'}}
                    name="resume_template_three" data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages" role="tab" aria-controls="v-pills-messages"
                    aria-selected="false">
                    Профессиональный
                </button>
                <button className="btn rounded-2 px-5 py-3 mt-5 btnprimary" onclick="go_to_pdf();"
                        style={{color: 'white', backgroundColor: '#6757F1', width: 'fit-content'}}>Скачать
                    PDF-резюме
                </button>
            </div>

            <div className="col-lg tab-content order-lg-last mb-4" id="v-pills-tabContent">
                <div className="tab-pane fade show active bg-info" id="v-pills-home" role="tabpanel"
                     aria-labelledby="v-pills-home-tab">
                    <img
                        className="img-fluid d-none d-md-block"
                        src={`${SOURCE.source_url}/r_tmp_full.jpg`}
                        style={{filter: "drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))"}}
                        alt="resume-example"
                    />
                    <img
                        className="d-block d-md-none img-fluid"
                        src={`${SOURCE.source_url}/r_tmp_small.jpg`}
                        style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                        alt="resume-example"
                    />
                </div>
                <div
                    className="tab-pane fade"
                    id="v-pills-profile" role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                >
                    <img
                        className="img-fluid d-none d-md-block"
                        src={`${SOURCE.source_url}/r_tmp_full-1.jpg`}
                        style={{filter: "drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))"}}
                        alt="resume-example"
                    />
                    <img
                        className="d-block d-md-none img-fluid"
                        src={`${SOURCE.source_url}/r_tmp_small-1.jpg`}
                        style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                        alt="resume-example-small"
                    />
                </div>
                <div
                    className="tab-pane fade"
                    id="v-pills-messages" role="tabpanel"
                    aria-labelledby="v-pills-messages-tab">
                    <img
                        className="img-fluid d-none d-md-block"
                        src={`${SOURCE.source_url}/r_tmp_full-2.jpg`}
                        style={{filter: "drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))"}}
                        alt="resume-example-full"
                    />
                    <img
                        className="d-block d-md-none img-fluid"
                        src={`${SOURCE.source_url}/r_tmp_small-2.jpg`}
                        style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                        alt='resume-example-small'
                    />
                </div>
            </div>
        </div>
    </div>
}