import './styles.css';
import {useDispatch} from "react-redux";
import {
    createCertificate,
    createEducation,
    createExperience, createLanguage,
    createSkill,
    updateServerResumeInner
} from "../ConstructorResumeBlock/api";
import {useDebounceCallback} from "../../constants";

export default function ConstructorSectionsBlock({ user, resume }) {
    const dispatch = useDispatch();
    const updateServerResume = useDebounceCallback(updateServerResumeInner, 500);
    return <div className="bg-white rounded-3 w-100 px-4 py-4 d-flex flex-column gap-3">
        <span className="fs-5 fw-semibold mb-1">Секции</span>
        <button className="fs-6 section-btn" disabled={resume.experience && resume.experience.length} onClick={() => createExperience(user.access_token, resume, dispatch)}>Опыт работы</button>
        <button className="fs-6 section-btn" disabled={resume.education && resume.education.length} onClick={() => createEducation(user.access_token, resume, dispatch)}>Образование</button>
        <button className="fs-6 section-btn" disabled={resume.certificates && resume.certificates.length} onClick={() =>  createCertificate(user.access_token, resume, dispatch)}>Курсы и сертификаты</button>
        <button className="fs-6 section-btn" disabled={resume.skills && resume.skills.length} onClick={() => createSkill(user.access_token, resume, dispatch, updateServerResume)}>Навыки</button>
        <button className="fs-6 section-btn" disabled={resume.languages && resume.languages.length} onClick={() => createLanguage(user.access_token, resume, dispatch)}>Языки</button>
    </div>
}