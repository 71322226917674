import {useDispatch} from "react-redux";
import {useNavigate} from "react-router";
import {useEffect} from "react";
import {setAuth} from "../store/slices/userSlice";

export default function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setAuth({access_token: ''}))
        navigate('/')
    }, [dispatch, navigate])

    return <></>
}