import {
    setCertificates,
    setEducation,
    setExperience,
    setLanguages,
    setResume,
    setSkills
} from "../../store/slices/resumeSlice";
import {SOURCE} from "../../constants";
import {getResumeAvatar} from "../../screens/ConstructorPage/utils";
import {setPersonalDetails} from "../../store/slices/userSlice";

export const createSkill = (access_token, resume, dispatch, updateServerResume) => {
    const updatedSkills = [...resume.skills, ""];
    dispatch(setSkills(updatedSkills));
    updateServerResume(access_token, resume.id, {skills: updatedSkills});
}

export const updateSkill = (access_token, resume, skillId, newSkill, dispatch, updateServerResume) => {
    const updatedSkills = resume.skills.map((s, i) => i === skillId ? newSkill : s);
    dispatch(setSkills(updatedSkills));
    updateServerResume(access_token, resume.id, {skills: updatedSkills});
}

export const deleteSkill = (access_token, resume, skillIdx, dispatch, updateServerResume) => {
    const updatedSkills = resume.skills.filter((s, i) => i !== skillIdx);
    dispatch(setSkills(updatedSkills));
    updateServerResume(access_token, resume.id, {skills: updatedSkills});

}

export const updateServerResumeInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/resume/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const updateResume = (access_token, resume, updated, dispatch, updateServerResume) => {
    dispatch(setResume({...resume, ...updated}));
    updateServerResume(access_token, resume.id, updated);
}

export const updateServerPersonalDetailsInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/personalDetails/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const updatePersonalDetails = (access_token, personalDetails, updated, dispatch, updateServerPD) => {
    dispatch(setPersonalDetails({...personalDetails, ...updated}));
    updateServerPD(access_token, personalDetails.id, updated);
}

export const createExperience = async (access_token, resume, dispatch) => {
    const dateNow = new Date();
    const res = await fetch(SOURCE.url + "/api/v1/experience?resumeId="+resume.id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({
            description: "",
            company: "",
            position: "",
            startMonth: dateNow.getMonth()+1,
            startYear: dateNow.getFullYear(),
            endMonth: dateNow.getMonth()+1,
            endYear: dateNow.getFullYear()
        })
    });
    if (!res.ok) return;
    const data = await res.json();
    data.startMonth = data.startMonth.toString().padStart(2, "0");
    data.endMonth = data.endMonth.toString().padStart(2, "0");
    await dispatch(setExperience([...resume.experience, data]));
}

export const updateServerExperienceInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/experience/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const editExperience = async (access_token, experienceId, updated, resumeExp, dispatch, updateServerExperience) => {
    const experience = {...resumeExp.find(e => e.id === experienceId), ...updated};
    await dispatch(setExperience(resumeExp.map(e => e.id === experienceId ? experience : e)));
    if (updated.hasOwnProperty("startMonth")) updated.startMonth = +updated.startMonth;
    if (updated.hasOwnProperty("endMonth")) updated.endMonth = +updated.endMonth;
    updateServerExperience(access_token, experienceId, updated);
}

export const deleteExperience = async (access_token, id, resumeExp, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/experience/"+id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
    });
    if (!res.ok) return;
    await dispatch(setExperience(resumeExp.filter(e => e.id !== id)));
}

export const createEducation = async (access_token, resume, dispatch) => {
    const dateNow = new Date();
    const res = await fetch(SOURCE.url + "/api/v1/education?resumeId="+resume.id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({
            description: "",
            name: "",
            degree: "",
            startYear: dateNow.getFullYear(),
            endYear: dateNow.getFullYear()
        })
    });
    if (!res.ok) return;
    const data = await res.json();
    await dispatch(setEducation([...resume.education, data]));
}

export const updateServerEducationInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/education/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const editEducation = async (access_token, educationId, updated, resumeEd, dispatch, updateServerEducation) => {
    const education = {...resumeEd.find(e => e.id === educationId), ...updated};
    await dispatch(setEducation(resumeEd.map(e => e.id === educationId ? education : e)));
    updateServerEducation(access_token, educationId, updated);
}

export const deleteEducation = async (access_token, id, resumeEd, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/education/"+id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
    });
    if (!res.ok) return;
    await dispatch(setEducation(resumeEd.filter(e => e.id !== id)));
}

export const createCertificate = async (access_token, resume, dispatch) => {
    const dateNow = new Date();
    const res = await fetch(SOURCE.url + "/api/v1/certificate?resumeId="+resume.id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({
            description: "",
            name: "",
            year: dateNow.getFullYear()
        })
    });
    if (!res.ok) return;
    const data = await res.json();
    await dispatch(setCertificates([...resume.certificates, data]));
}

export const updateServerCertificateInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/certificate/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const editCertificate = async (access_token, certificateId, updated, resumeCert, dispatch, updateServerCertificates) => {
    const education = {...resumeCert.find(e => e.id === certificateId), ...updated};
    await dispatch(setCertificates(resumeCert.map(e => e.id === certificateId ? education : e)));
    updateServerCertificates(access_token, certificateId, updated);
}

export const deleteCertificate = async (access_token, id, resumeCert, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/certificate/"+id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
    });
    if (!res.ok) return;
    await dispatch(setCertificates(resumeCert.filter(e => e.id !== id)));
}

export const createLanguage = async (access_token, resume, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/language?resumeId="+resume.id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({
            level: "A1",
            name: "",
        })
    });
    if (!res.ok) return;
    const data = await res.json();
    await dispatch(setLanguages([...resume.languages, data]));
}

export const updateServerLanguageInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/language/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const editLanguage = async (access_token, languageId, updated, resumeLang, dispatch, updateServerLanguage) => {
    const language = {...resumeLang.find(e => e.id === languageId), ...updated};
    await dispatch(setLanguages(resumeLang.map(e => e.id === languageId ? language : e)));
    updateServerLanguage(access_token, languageId, updated);
}

export const deleteLanguage = async (access_token, id, resumeLang, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/language/"+id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
    });
    if (!res.ok) return;
    await dispatch(setLanguages(resumeLang.filter(e => e.id !== id)));
}

export const uploadAvatar = async (access_token, id, file, setState) => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await fetch(`${SOURCE.url}/api/v1/resume/${id}/avatar`, {
            method: "PUT",
            headers: {
                Authorization: access_token,
            },
            body: formData,
        });
        if (res.ok) {
            const avatar = await getResumeAvatar(access_token, id);
            setState(p => ({...p, avatarUrl: URL.createObjectURL(avatar)}));
        } else {
            const data = await res.json();
            setState(p => ({...p, errorMsg: data.error}));
        }
    } catch (error) {
        console.error(error);
        setState(p => ({...p, errorMsg: "Произошла ошибка"}));
    }
}