import Modal from 'react-bootstrap/Modal';
import {COLORS} from '../../constants';
import {useNavigate} from 'react-router';

export const LogoutModal = ({shown, setState}) => {

    const navigate = useNavigate()
    return (
        <Modal dialogClassName="rounded-4" centered show={shown}
               onHide={() => setState(p => ({...p, logoutModalShown: false}))}>
            <Modal.Header closeButton>
                <Modal.Title style={{fontWeight: '600'}}>Подтверждение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Вы точно хотите выйти?
            </Modal.Body>
            <Modal.Footer>
                <button className='btn primary' onClick={() => setState(p => ({...p, logoutModalShown: false}))}>
                    Нет, остаться!
                </button>
                <button className='btn text-nowrap light_primary px-3'
                        style={{backgroundColor: COLORS.footer, color: COLORS.primary, maxWidth: '100px'}}
                        onClick={() => {
                            setState(p => ({...p, logoutModalShown: false}));
                            navigate('/logout')
                        }}>
                    Выйти
                </button>
            </Modal.Footer>
        </Modal>
    )
}