import {COLORS, ErrorMsg, SOURCE, USER_MESSAGES_ERROR, CONSOLE_MESSAGES_ERROR} from "../constants"
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export const OldMainLanding = () => {
    const [state, setState] = useState({
        hhLoginUrl: '',
        errorMsg: '',
    })
    const user = useSelector(state => state.userSlice.user)

    useEffect(() => {
        fetch(`${SOURCE.url}/api/v1/auth/hhAuthorizeUrl`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => setState(state => ({...state, hhLoginUrl: res})))
        .catch(e => {
            setState(state => ({...state, errorMsg: USER_MESSAGES_ERROR.AUTH}))
            console.error(CONSOLE_MESSAGES_ERROR.AUTH, e)
        })
    }, [])

    return <div className='w-100 d-flex flex-column'>
        {state.errorMsg && <ErrorMsg state={state} setState={setState}/>}
        <img src={SOURCE.source_url + "resume_mockup.png"} className="m-auto"
             style={{height: 'auto', width: '100%', maxWidth: '800px'}} alt="resume_mockup"/>
        {user.access_token ?
            <a href="/select_resume" className="btn red_primary rounded-2 px-5 py-3 mx-auto mb-4">Выбрать резюме</a>
            :
            <button className="btn red_primary rounded-2 px-5 py-3 mx-auto mb-4" onClick={() => {
                if (state.hhLoginUrl) window.location.href = state.hhLoginUrl
            }}>
                Войти через hh.ru
            </button>
        }
        <p className="fs-1 text-center mt-5" style={{color: COLORS.primary, fontWeight: '900',}}>Это сложно?</p>
        <div className="row gap-5 mt-5 mb-4">
            <div className="col">
                <div style={{backgroundColor: COLORS.light_primary, width: '120px', height: '120px'}}
                     className="d-flex rounded-4 mx-sm-0 mx-auto">
                    <svg width="50px" className="m-auto" viewBox="0 0 49 36" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M31.1276 0C28.7849 0 26.5381 0.948211 24.8815 2.63604C23.2249 4.32387 22.2943 6.61305 22.2943 9C22.2943 11.3869 23.2249 13.6761 24.8815 15.364C26.5381 17.0518 28.7849 18 31.1276 18C33.4703 18 35.7171 17.0518 37.3737 15.364C39.0303 13.6761 39.9609 11.3869 39.9609 9C39.9609 6.61305 39.0303 4.32387 37.3737 2.63604C35.7171 0.948211 33.4703 0 31.1276 0ZM31.1276 4.275C31.7366 4.275 32.3396 4.39722 32.9023 4.63467C33.4649 4.87212 33.9762 5.22016 34.4068 5.65892C34.8374 6.09768 35.179 6.61856 35.4121 7.19182C35.6452 7.76508 35.7651 8.3795 35.7651 9C35.7651 9.6205 35.6452 10.2349 35.4121 10.8082C35.179 11.3814 34.8374 11.9023 34.4068 12.3411C33.9762 12.7798 33.4649 13.1279 32.9023 13.3653C32.3396 13.6028 31.7366 13.725 31.1276 13.725C30.5186 13.725 29.9156 13.6028 29.3529 13.3653C28.7903 13.1279 28.279 12.7798 27.8484 12.3411C27.4178 11.9023 27.0762 11.3814 26.8431 10.8082C26.6101 10.2349 26.4901 9.6205 26.4901 9C26.4901 7.74685 26.9787 6.54503 27.8484 5.65892C28.7181 4.77281 29.8977 4.275 31.1276 4.275ZM6.83594 6.75V13.5H0.210938V18H6.83594V24.75H11.2526V18H17.8776V13.5H11.2526V6.75H6.83594ZM31.1276 20.25C25.2314 20.25 13.4609 23.2425 13.4609 29.25V36H48.7943V29.25C48.7943 23.2425 37.0239 20.25 31.1276 20.25ZM31.1276 24.525C37.6864 24.525 44.5984 27.81 44.5984 29.25V31.725H17.6568V29.25C17.6568 27.81 24.5026 24.525 31.1276 24.525Z"
                            fill={COLORS.primary}/>
                    </svg>
                </div>
                <p className="fs-4 mt-4 mb-3 text-sm-start text-center text-nowrap"
                   style={{color: COLORS.primary, fontWeight: 600}}>1. Регистраиция</p>
                <p className="fs-4 text-sm-start text-center">Просто войди через свой аккаунт <span
                    style={{color: '#F8485D', fontWeight: 600}}>hh.ru</span></p>
            </div>
            <div className="col">
                <div style={{height: '120px', width: '120px', backgroundColor: COLORS.light_primary}}
                     className="d-flex rounded-4 mx-sm-0 mx-auto">
                    <svg width="44px" className="m-auto" viewBox="0 0 37 43" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M24.226 0.888672H3.52657C2.79763 0.907968 2.10576 1.21414 1.60129 1.74067C1.09682 2.26719 0.820508 2.97152 0.8324 3.70062V39.299C0.820508 40.028 1.09682 40.7324 1.60129 41.2589C2.10576 41.7854 2.79763 42.0916 3.52657 42.1109H33.4716C34.2005 42.0916 34.8924 41.7854 35.3968 41.2589C35.9013 40.7324 36.1776 40.028 36.1657 39.299V12.5487L24.226 0.888672ZM23.7843 4.5545L32.6177 13.2406H23.7843V4.5545ZM3.77684 39.1665V3.83312H21.4435V15.6109H33.2213V39.1665H3.77684Z"
                            fill={COLORS.primary}/>
                    </svg>

                </div>
                <p className="fs-4 mt-4 mb-3 text-sm-start text-center text-nowrap"
                   style={{color: COLORS.primary, fontWeight: 600}}>2. Выбери шаблон</p>
                <p className="fs-4 text-sm-start text-center">Выбери любой из трех шаблонов и настрой его</p>
            </div>
            <div className="col">
                <div style={{height: '120px', width: '120px', backgroundColor: COLORS.light_primary}}
                     className="d-flex rounded-4 mx-sm-0 mx-auto">
                    <svg width="44px" className="m-auto" viewBox="0 0 53 47" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.65625 29.7939C2.09551 29.7939 2.51679 29.9684 2.8274 30.2791C3.138 30.5897 3.3125 31.0109 3.3125 31.4502V39.7314C3.3125 40.61 3.66149 41.4525 4.28271 42.0737C4.90392 42.695 5.74647 43.0439 6.625 43.0439H46.375C47.2535 43.0439 48.0961 42.695 48.7173 42.0737C49.3385 41.4525 49.6875 40.61 49.6875 39.7314V31.4502C49.6875 31.0109 49.862 30.5897 50.1726 30.2791C50.4832 29.9684 50.9045 29.7939 51.3438 29.7939C51.783 29.7939 52.2043 29.9684 52.5149 30.2791C52.8255 30.5897 53 31.0109 53 31.4502V39.7314C53 41.4885 52.302 43.1736 51.0596 44.416C49.8172 45.6585 48.1321 46.3564 46.375 46.3564H6.625C4.86794 46.3564 3.18285 45.6585 1.94042 44.416C0.697989 43.1736 0 41.4885 0 39.7314V31.4502C0 31.0109 0.174497 30.5897 0.485104 30.2791C0.795712 29.9684 1.21699 29.7939 1.65625 29.7939Z"
                            fill={COLORS.primary}/>
                        <path
                            d="M25.3256 36.2664C25.4794 36.4206 25.6622 36.543 25.8634 36.6265C26.0646 36.71 26.2803 36.753 26.4982 36.753C26.716 36.753 26.9318 36.71 27.133 36.6265C27.3342 36.543 27.517 36.4206 27.6708 36.2664L37.6083 26.3289C37.9193 26.0179 38.094 25.5961 38.094 25.1562C38.094 24.7164 37.9193 24.2946 37.6083 23.9836C37.2973 23.6726 36.8755 23.4979 36.4357 23.4979C35.9959 23.4979 35.5741 23.6726 35.2631 23.9836L28.1544 31.0956V1.96875C28.1544 1.52949 27.9799 1.10821 27.6693 0.797604C27.3587 0.486997 26.9375 0.3125 26.4982 0.3125C26.0589 0.3125 25.6376 0.486997 25.327 0.797604C25.0164 1.10821 24.8419 1.52949 24.8419 1.96875V31.0956L17.7333 23.9836C17.4223 23.6726 17.0005 23.4979 16.5607 23.4979C16.1209 23.4979 15.6991 23.6726 15.3881 23.9836C15.0771 24.2946 14.9023 24.7164 14.9023 25.1562C14.9023 25.5961 15.0771 26.0179 15.3881 26.3289L25.3256 36.2664Z"
                            fill={COLORS.primary}/>
                    </svg>
                </div>
                <p className="fs-4 mt-4 mb-3 text-sm-start text-center text-nowrap"
                   style={{color: COLORS.primary, fontWeight: '600'}}>3. Скачай PDF</p>
                <p className="fs-4 text-sm-start text-center">Готово! Остается просто скачать готовый файл</p>
            </div>
        </div>
        <p className="fs-1 text-center mt-5 mb-5" style={{color: COLORS.primary, fontWeight: '900'}}>Сделано
            профессионалами</p>
        <div className="row gap-5 mb-5">
            <div className="col">
                <p className="fs-4" style={{color: '#23329B'}}>Этот сервис создан совместно с опытными HR-специалистами
                    и
                    одобрен рекрутерами крупнейших российских компаний. Каждый шаблон прошел не один десяток
                    проверок, так что ты можешь быть уверен в надежности твоего резюме.</p>
            </div>
            <div className="col offset-xl-1">
                <p className="rounded-3 px-4 py-3 fs-5 mb-3"
                   style={{
                       color: COLORS.primary,
                       border: '1px solid ' + COLORS.primary,
                       fontWeight: '600',
                       width: 'fit-content'
                   }}>Без
                    водяных знаков</p>
                <p className="rounded-3 px-4 py-3 fs-5 mb-3"
                   style={{
                       color: COLORS.primary,
                       border: '1px solid ' + COLORS.primary,
                       fontWeight: '600',
                       width: 'fit-content'
                   }}>
                    Полностью кастомизируемый</p>
                <p className="rounded-3 px-4 py-3 fs-5 mb-3"
                   style={{
                       color: COLORS.primary,
                       border: '1px solid ' + COLORS.primary,
                       fontWeight: '600',
                       width: 'fit-content'
                   }}>
                    Абсолютно бесплатный</p>
            </div>
        </div>
        <p className="fs-1 text-center mt-5 mb-2" style={{color: COLORS.primary, fontWeight: '900'}}>Шаблоны для
            успеха</p>
        <p className="fs-4 text-center" style={{color: '#23329B'}}>Выбирай один из трех шаблонов резюме, созданных
            специально для хартл</p>
        <div className="row mt-5">
            <div
                className="col nav flex-column nav-pills order-lg-first order-last align-items-center align-items-md-start"
                id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <button className="nav-link active btn fs-1 p-0 mb-2 mt-4 red_primary"
                        style={{background: 'none', width: 'fit-content', fontWeight: 'bold'}}
                        id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                        role="tab" aria-controls="v-pills-home" aria-selected="true">Простой
                </button>
                <button className="nav-link btn fs-1 p-0 mb-2 red_primary"
                        style={{background: 'none', width: 'fit-content', fontWeight: 'bold'}}
                        id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile"
                        type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Современный
                </button>
                <button className="nav-link btn fs-1 p-0 mb-2 red_primary"
                        style={{background: 'none', width: 'fit-content', fontWeight: 'bold'}}
                        id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages"
                        type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                    Профессиональный
                </button>
                {user.access_token ?
                    <a href="/select_resume" className="btn rounded-2 px-5 py-3 mt-5 d-none d-md-block red_primary">Выбрать резюме</a>
                    :
                    <button className="btn rounded-2 px-5 py-3 mt-5 d-none d-md-block red_primary" onClick={() => {
                        if (state.hhLoginUrl) window.location.href = state.hhLoginUrl
                    }}>
                        Войти через hh.ru
                    </button>
                }
            </div>
            <div className="col-lg tab-content order-lg-last mb-4" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                     aria-labelledby="v-pills-home-tab">
                    <img src={`${SOURCE.source_url}/r_tmp_full.jpg`}
                         style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                         className="img-fluid d-none d-md-block"
                         alt="r_tmp_full"/>
                    <img src={`${SOURCE.source_url}/r_tmp_small.jpg`}
                         style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                         className="d-block d-md-none img-fluid"
                         alt="r_tmp_small"/>
                </div>
                <div className="tab-pane fade" id="v-pills-profile" role="tabpanel"
                     aria-labelledby="v-pills-profile-tab">
                    <img src={`${SOURCE.source_url}/r_tmp_full-1.jpg`}
                         style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                         className="img-fluid d-none d-md-block"
                         alt="r_tmp_full-1"/>
                    <img src={`${SOURCE.source_url}/r_tmp_small-1.jpg`}
                         style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03)) '}}
                         className="d-block d-md-none img-fluid"
                         alt="r_tmp_small-1"/>
                </div>
                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel"
                     aria-labelledby="v-pills-messages-tab">
                    <img src={`${SOURCE.source_url}/r_tmp_full-2.jpg`}
                         style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                         className="img-fluid d-none d-md-block"
                         alt="r_tmp_full-2"/>
                    <img src={`${SOURCE.source_url}/r_tmp_small-2.jpg`}
                         style={{filter: 'drop-shadow(0px 0px 40px rgba(0, 0, 0, 0.03))'}}
                         className="d-block d-md-none img-fluid"
                         alt="r_tmp_small-2"/>
                </div>
            </div>
        </div>
        <div className="d-flex flex-column mt-5">
            <p className="fs-1 text-center mb-3" style={{fontWeight: '900', color: COLORS.primary}}>Бесплатно. Точно
                бесплатно</p>
            <p className="fs-4 text-center mb-5" style={{fontWeight: '600', color: '#23329B'}}>Резюме обязано быть
                качественным и это не должно стоить денег</p>
            <div className="rounded-4 mx-auto px-5 py-4 w-100"
                 style={{border: '1px solid ' + COLORS.primary, maxWidth: '500px'}}>
                <p className="fs-4" style={{fontWeight: '600'}}>Полный доступ</p>
                <p className="fs-3" style={{fontWeight: '600'}}><span style={{fontWeight: '900'}}>0 руб.</span> навсегда
                </p>
                <p className="fs-5 mt-2">- импорт из hh.ru</p>
                <p className="fs-5">- настройка шаблона</p>
                <p className="fs-5">- скачивание PDF файла</p>
                <p className="fs-5">- без водяных знаков</p>
            </div>
        </div>

        {user.access_token ?
            <a href="/select_resume" className="btn rounded-2 red_primary px-5 py-3 mx-auto mt-5 mb-3">Выбрать резюме</a>
            :
            <button className="btn rounded-2 red_primary px-5 py-3 mx-auto mt-5 mb-3" onClick={() => {
                if (state.hhLoginUrl) window.location.href = state.hhLoginUrl
            }}>
                Войти через hh.ru
            </button>
        }
    </div>
}