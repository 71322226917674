import {createSlice} from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import {jwtDecode} from "jwt-decode";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {
            id: Cookies.get('access_token') ? jwtDecode(Cookies.get('access_token')).id : null,
            loaded: false,
            access_token: Cookies.get('access_token'),
            personalDetails: {
                id: null,
                firstName: null,
                lastName: null,
                middleName: null,
                gender: null,
                birthDate: null,
                userId: null
            }
        },
        cookie_accepted: Cookies.get('cookie_accepted')
    },
    reducers: {
        setAuth: (state, action) => {
            Cookies.set('access_token', action.payload.access_token, {expires: 365})
            state.user.access_token = action.payload.access_token
            if (state.user.access_token) state.user.id = jwtDecode(action.payload.access_token).id
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setCookieAcception: (state, action) => {
            Cookies.set('cookie_accepted', action.payload.accepted)
            state.cookie_accepted = action.payload.accepted
        },
        setPersonalDetails: (state, action) => {
            state.user.personalDetails = action.payload;
        }
    },
})


// Action creators are generated for each case reducer function
export const {setAuth, setUser, setCookieAcception, setPersonalDetails} = userSlice.actions

export default userSlice.reducer