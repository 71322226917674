import {SOURCE} from "../../constants";
import {setContacts} from "../../store/slices/resumeSlice";

export const createContact = async (access_token, type, resume, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/contact/?resumeId=" + resume.id, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify({
            type,
            name: "",
            value: "",
            preferred: true
        })
    });
    if (!res.ok) return;
    const data = await res.json();
    await dispatch(setContacts([...resume.contacts, data]));
}

export const updateServerContactInner = (access_token, id, updated) => {
    fetch(SOURCE.url + "/api/v1/contact/"+id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
        body: JSON.stringify(updated)
    }).catch((err) => console.error(err));
}

export const editContact = async (access_token, contactId, updated, resumeContacts, dispatch, updateServerContact) => {
    const contact = {...resumeContacts.find(e => e.id === contactId), ...updated};
    await dispatch(setContacts(resumeContacts.map(e => e.id === contactId ? contact : e)));
    updateServerContact(access_token, contactId, updated);
}

export const deleteContact = async (access_token, id, resumeContacts, dispatch) => {
    const res = await fetch(SOURCE.url + "/api/v1/contact/"+id, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": access_token
        },
    });
    if (!res.ok) return;
    await dispatch(setContacts(resumeContacts.filter(e => e.id !== id)));
}