// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dropdown__u88vD {
    z-index: 1;
    position: relative;
    padding: 0;
}

.styles_list__mTs\\+d {
    padding: 30px;
    position: absolute;
    list-style-type: none;
    background-color: white;
    border: 1px solid gray;
    border-radius: 15px;
    left: 0;
    bottom: 2rem;
    display: flex;
    flex-direction: column;

}

.styles_item__UdQ2m {
    padding: 6px 24px;
    text-align: center;
    background-color: white;
}


.styles_item__UdQ2m:hover {
    transition: all 0.025s;
    background-color: var(--primary);
    color: white;
}


.styles_closeBtn__nBddy {
    top: 8px;
    right: 8px;
    position: absolute;
}

@media screen and (max-width: 575px) {

    .styles_list__mTs\\+d {
        padding: 20px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/SaveResumeConstructorButton/styles.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,OAAO;IACP,YAAY;IACZ,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;AAC3B;;;AAGA;IACI,sBAAsB;IACtB,gCAAgC;IAChC,YAAY;AAChB;;;AAGA;IACI,QAAQ;IACR,UAAU;IACV,kBAAkB;AACtB;;AAEA;;IAEI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".dropdown {\n    z-index: 1;\n    position: relative;\n    padding: 0;\n}\n\n.list {\n    padding: 30px;\n    position: absolute;\n    list-style-type: none;\n    background-color: white;\n    border: 1px solid gray;\n    border-radius: 15px;\n    left: 0;\n    bottom: 2rem;\n    display: flex;\n    flex-direction: column;\n\n}\n\n.item {\n    padding: 6px 24px;\n    text-align: center;\n    background-color: white;\n}\n\n\n.item:hover {\n    transition: all 0.025s;\n    background-color: var(--primary);\n    color: white;\n}\n\n\n.closeBtn {\n    top: 8px;\n    right: 8px;\n    position: absolute;\n}\n\n@media screen and (max-width: 575px) {\n\n    .list {\n        padding: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `styles_dropdown__u88vD`,
	"list": `styles_list__mTs+d`,
	"item": `styles_item__UdQ2m`,
	"closeBtn": `styles_closeBtn__nBddy`
};
export default ___CSS_LOADER_EXPORT___;
