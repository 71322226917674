import {useNavigate} from "react-router"
import {COLORS} from "../constants"

export const ResumeNotFound = () => {
    const navigate = useNavigate()
    return <div className="container-fluid d-flex flex-column justify-content-center text-center my-5"
                style={{minHeight: '60vh'}}>
        <p class="fs-2">Извини, но мы не нашли у тебя резюме :с </p>
        <button onClick={() => navigate('/')} class="btn px-5 py-2 rounded-2 mx-auto fs-5"
                style={{border: '1px solid ' + COLORS.primary, color: COLORS.primary}}>
            На главную
        </button>
    </div>
}