import {
    COLORS, 
    ErrorMsg, 
    SOURCE, 
    USER_MESSAGES_ERROR, 
    CONSOLE_MESSAGES_ERROR
} from "../constants"
import {ReactComponent as HeaderCollapseSvg} from "../icons/main_landing/header-collapse.svg";
import {useEffect, useState} from "react"
import {Nav, Navbar} from "react-bootstrap"
import {useDispatch, useSelector} from "react-redux"
import {useLocation, useNavigate} from "react-router"
import {LogoutModal} from "./modals/LogoutModal"
import { refreshPersonalDetails } from "../screens/Auth"
import { Loader } from "./Loader"

export const MainHeader = () => {
    const navigate = useNavigate()
    const loc = useLocation()
    const user = useSelector(state => state.userSlice.user)
    const [state, setState] = useState({
        logoutModalShown: false,
        errorMsg: '',
        hhLoginUrl: ''
    })
    const dispatch = useDispatch()

    useEffect(() => {
        if (!user.access_token) fetch(`${SOURCE.url}/api/v1/auth/hhAuthorizeUrl`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then(res => setState(state => ({...state, hhLoginUrl: res})))
        .catch(e => {
            setState({...state, errorMsg: USER_MESSAGES_ERROR.AUTH})
            console.error(CONSOLE_MESSAGES_ERROR.AUTH, e)
        })
        else if (user.id) refreshPersonalDetails(user, dispatch, setState, navigate)
    }, [user.access_token])

    return <div className="pt-3 pb-2 w-100 mx-auto">
        {state.errorMsg && <ErrorMsg state={state} setState={setState}/>}
        <LogoutModal shown={state.logoutModalShown} setState={setState}/>
            <Navbar.Collapse>
                <Nav className="me-auto mt-md-0 mt-3">
                    <Nav.Link className="text-nowrap fs-6 fw-semibold" eventKey="2" onClick={() => navigate('/constructor')}
                              style={{color: COLORS.dark_primary}}>
                        Конструктор резюме
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        <Navbar collapseOnSelect expand="md">
            <Navbar.Brand className="me-5">
                <span className="fw-bold fs-3" style={{color: COLORS.dark_primary, cursor: 'pointer'}} onClick={() => navigate('/')}>хартл</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" bsPrefix='no_outline'>
                <HeaderCollapseSvg style={{cursor: "pointer"}} className="no_outline d-block d-md-none" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav pe-4">
                <div className="d-flex align-items-md-center flex-md-row flex-column w-100">
                    <Nav className="me-auto">
                        <DefaultBar pathname={loc.pathname}/>
                    </Nav>
                    {user.access_token ?
                        <div className="d-flex flex-column align-items-md-end mt-md-0 mt-2">
                            {(!user.loaded || !user.personalDetails) ? <Loader size="small" animation={false} /> : <span className="fw-light">Вы вошли как <span className="fw-normal">{user.personalDetails.firstName+" "+user.personalDetails.lastName}</span></span>}
                            <Nav.Link className="btn btn-link fw-light m-0 p-0 lh-1"
                                    style={{color: COLORS.primary, width: "fit-content"}}
                                    onClick={() => setState(state => ({...state, logoutModalShown: true}))}>выйти
                            </Nav.Link>
                        </div>
                        :
                        <Nav className="mt-md-0 mt-2">
                            <Nav.Link
                            className="btn rounded-4 me-auto fs-5 fw-bold orange py-2 px-4" onClick={() => {
                                if (state.hhLoginUrl) window.location.href = state.hhLoginUrl
                            }}>Войти
                            </Nav.Link>
                        </Nav>
                    }
                </div>
            </Navbar.Collapse>
        </Navbar>
    </div>
}

const DefaultBar = ({pathname}) => {
    const navigate = useNavigate()
    return <>
                <Nav className="me-auto">
                    <Nav.Link className="text-nowrap fs-6 fw-semibold" eventKey="2" onClick={() => navigate('/constructor')}
                              style={{color: pathname.includes('select_resume') || pathname.includes('constructor') ? COLORS.dark_primary : COLORS.header_text}}>
                        Конструктор резюме
                    </Nav.Link>
                </Nav>
        <Nav.Link className="text-nowrap fw-semibold" eventKey="2" onClick={() => navigate('/contacts')}
            style={{
                color: pathname.includes('contacts') ? COLORS.dark_primary : COLORS.header_text,
            }}>Контакты</Nav.Link>
    </>
}
