// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_section__54i3g {
    font-weight: 600;
    color: #909090;
}

.styles_contacts__-VwUP {
    font-size: 0.625rem;
    color: #6F7C7F;
}

.styles_wrapper__GRjyH {
    margin-bottom: 1.375rem;
}

.styles_experience__eXA4h {
    font-size: 0.75rem;
    color: '#B8B8B8'
}

.styles_job__YbDAj {
    font-size: 0.95rem;
    font-weight: 600;
    margin-bottom: 7px;
}

.styles_description__-chN7 {
    font-size: 0.8rem;
    color: #909090;
}

.styles_box__Y9XNE {
    color: black;
    font-size: 0.813rem;
    margin-bottom: 0.688rem;
}

.styles_list__7ZSmb {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.styles_item__grX3x {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/PdfResumePages/pageTwo/styles.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB;AACJ;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".section {\n    font-weight: 600;\n    color: #909090;\n}\n\n.contacts {\n    font-size: 0.625rem;\n    color: #6F7C7F;\n}\n\n.wrapper {\n    margin-bottom: 1.375rem;\n}\n\n.experience {\n    font-size: 0.75rem;\n    color: '#B8B8B8'\n}\n\n.job {\n    font-size: 0.95rem;\n    font-weight: 600;\n    margin-bottom: 7px;\n}\n\n.description {\n    font-size: 0.8rem;\n    color: #909090;\n}\n\n.box {\n    color: black;\n    font-size: 0.813rem;\n    margin-bottom: 0.688rem;\n}\n\n.list {\n    margin: 0;\n    padding: 0;\n    list-style-type: none;\n}\n\n.item {\n    font-size: 0.75rem;\n    margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `styles_section__54i3g`,
	"contacts": `styles_contacts__-VwUP`,
	"wrapper": `styles_wrapper__GRjyH`,
	"experience": `styles_experience__eXA4h`,
	"job": `styles_job__YbDAj`,
	"description": `styles_description__-chN7`,
	"box": `styles_box__Y9XNE`,
	"list": `styles_list__7ZSmb`,
	"item": `styles_item__grX3x`
};
export default ___CSS_LOADER_EXPORT___;
