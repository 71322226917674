import { 
    COLORS, 
    ErrorMsg, 
    SOURCE, 
    USER_MESSAGES_ERROR, 
    CONSOLE_MESSAGES_ERROR 
} from "../constants";
import {ReactComponent as BaselinePlaceSvg} from "../icons/baseline-place.svg";
import {ReactComponent as TwoCoinsSvg} from "../icons/two-coins.svg";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {SaveResumeSelect} from "../components/SaveResumeSelect/SaveResumeSelect";

export default function SelectResume() {
    const navigate = useNavigate();
    const user = useSelector(state => state.userSlice.user);
    const [state, setState] = useState({
        user_resumes: [],
        import_resumes: [],
        loading: true,
        errorMsg: null
    });

    useEffect(() => {
        if (!user.access_token) navigate('/')
        fetch(`${SOURCE.url}/api/v1/resume/all`, {
            method: 'GET',
            headers: {
                Authorization: user.access_token
            }
        })
        .then(res => res.json())
        .then(res => {
            setState(p => ({...p, user_resumes: res, loading: false}));
            res.forEach(resume => {
                fetch(`${SOURCE.url}/api/v1/resume/${resume.id}/avatar`, {
                    headers: {
                        Authorization: user.access_token
                    }
                }).then(res => res.ok && res.blob()
                    .then(data => setState(p => ({
                        ...p,
                        user_resumes: p.user_resumes.map(r => r.id === resume.id ? {...r, avatar: URL.createObjectURL(data)} : r)
                    }))))
            })
        })
        .catch(e => {
            setState({...state, errorMsg: USER_MESSAGES_ERROR.GET_ALL_RESUME})
            console.error(CONSOLE_MESSAGES_ERROR.GET_ALL_RESUME, e)
        })

        fetch(`${SOURCE.url}/api/v1/resume/readyToImport?externalServiceName=hh`, {
            method: 'GET',
            headers: {
                Authorization: user.access_token
            }
        })
        .then(res => res.json())
        .then(res => {
            setState(p => ({...p, import_resumes: res["resumes"], loading: false}))
        })
        .catch(e => {
            setState({...state, errorMsg: USER_MESSAGES_ERROR.IMPORT_RESUME})
            console.error(CONSOLE_MESSAGES_ERROR.IMPORT_RESUME, e)
        })
    }, [navigate, user.access_token])

    const importResumeHandler = async (resumeId) => {
        const req = await fetch(`${SOURCE.url}/api/v1/resume/import?externalServiceName=hh&externalServiceResumeId=${resumeId}`, {
            method: 'POST',
            headers: {
                Authorization: user.access_token
            }
        })
        const res = await req.json()
        if (res.error) {
            setState(p => ({...p, errorMsg: res.error}))
        } else {
            navigate('/constructor', {state: {resumeId: res.id}})
        }
    }

    const createResumeHandler = async () => {
        const req = await fetch(`${SOURCE.url}/api/v1/resume`, {
            method: 'POST',
            headers: {
                Authorization: user.access_token
            }
        })
        const res = await req.json()
        if (res.error) {
            setState(p => ({...p, errorMsg: res.error}))
        } else {
            navigate('/constructor', {state: {resumeId: res.id}})
        }
    }

    return <div className="d-flex flex-row flex-wrap gap-5 my-3 pb-5">
        <div className="position-fixed top-0 start-0 w-100 h-100 min-vw-100 min-vh-100" style={{backgroundColor: "#F8F7FB", zIndex: -1}}></div>
        {state.errorMsg && <ErrorMsg state={state} setState={setState}/>}
        <div className="col-12 col-lg d-flex flex-column gap-4">
            <span className="fs-3 fw-semibold">Твои резюме</span>
            {state.user_resumes.map(resume => (
                <div className="rounded-3 px-4 py-3 bg-white" key={resume.id}
                     style={{cursor: "pointer"}} onClick={() => navigate("/constructor", {state: {resumeId: resume.id}})}>
                    <div className="d-flex flex-row flex-wrap gap-3">
                        <div className="rounded-3" style={{width: "60px", height: "60px", boxShadow: "0px 0px 12px 0px rgba(103, 87, 241, 0.30)"}}>
                            {resume.avatar ?
                                <img src={resume.avatar} alt="avatar" className="w-100 h-100 rounded-3" /> :
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     className="bi bi-person w-100 h-100 rounded-3" viewBox="0 0 16 16">
                                    <path
                                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                                </svg>
                            }
                        </div>
                        <div className="flex-grow-1 d-flex flex-column justify-content-center">
                            <span className="fs-5 fw-semibold">{user.personalDetails.firstName+" "+user.personalDetails.lastName}</span>
                            <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>{resume.title || "Новое резюме"}</span>
                        </div>
                    </div>
                    <div className="w-100 d-flex flex-row flex-wrap mt-4 gap-2 gap-lg-3">
                        <div className="d-flex flex-row align-items-center flex-nowrap">
                            <BaselinePlaceSvg className="me-1" style={{width: "22px", height: "22px"}} />
                            <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>{resume.city || "Город не указан"}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center flex-nowrap">
                            <TwoCoinsSvg className="me-2" style={{width: "22px", height: "22px"}} />
                            <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>{resume.desiredSalary ? (resume.desiredSalary + " руб/мес") : "з/п не указана"}</span>
                        </div>
                    </div>
                    <SaveResumeSelect resumeId={resume.id} user={user} />
                </div>
            ))}
        </div>
        <div className="col-12 col-lg d-flex flex-column gap-4">
            <span className="fs-3 fw-semibold">Резюме с HH.RU</span>
            {state.import_resumes.map(resume => <div className="rounded-3 px-4 py-3 bg-white" key={resume.id}>
                <div className="d-flex flex-row flex-wrap gap-3">
                    <div className="rounded-3" style={{
                        width: "60px",
                        height: "60px",
                        boxShadow: "0px 0px 12px 0px rgba(103, 87, 241, 0.30)",
                        background: "radial-gradient(50% 50% at 50% 50%, #8C7FFF 0%, #6757F1 100%)"
                    }}></div>
                    <div className="flex-grow-1 d-flex flex-column justify-content-center">
                        <span className="fs-5 fw-semibold">{user.personalDetails.firstName+" "+user.personalDetails.lastName}</span>
                        <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>{resume.title || "Новое резюме"}</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row flex-wrap mt-4 gap-2 gap-lg-3">
                    <div className="d-flex flex-row align-items-center flex-nowrap">
                        <BaselinePlaceSvg className="me-1" style={{width: "22px", height: "22px"}} />
                        <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>город</span>
                    </div>
                    <div className="d-flex flex-row align-items-center flex-nowrap">
                        <TwoCoinsSvg className="me-2" style={{width: "22px", height: "22px"}} />
                        <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>зарплата</span>
                    </div>
                </div>
                <button className="w-100 px-3 py-2 btn mt-3 fw-semibold" onClick={() => importResumeHandler(resume.id)}
                        style={{backgroundColor: COLORS.neutral}}>Импортировать</button>
            </div>)}
        </div>
        <div className="col-12 col-lg d-flex flex-column gap-4">
            <span className="fs-3 fw-semibold">Новое резюме</span>
            <div className="rounded-3 px-4 py-3 bg-white">
                <div className="d-flex flex-row flex-wrap gap-3">
                    <div className="rounded-3" style={{
                        width: "60px",
                        height: "60px",
                        boxShadow: "0px 0px 12px 0px rgba(103, 87, 241, 0.30)",
                        background: "radial-gradient(50% 50% at 50% 50%, #8C7FFF 0%, #6757F1 100%)"
                    }}>
                    </div>
                    <div className="flex-grow-1 d-flex flex-column justify-content-center">
                        <span className="fs-5 fw-semibold">{user.personalDetails.firstName+" "+user.personalDetails.lastName}</span>
                        <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>Новое резюме</span>
                    </div>
                </div>
                <div className="w-100 d-flex flex-row flex-wrap mt-4 gap-2 gap-lg-3">
                    <div className="d-flex flex-row align-items-center flex-nowrap">
                        <BaselinePlaceSvg className="me-1" style={{width: "22px", height: "22px"}} />
                        <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>город</span>
                    </div>
                    <div className="d-flex flex-row align-items-center flex-nowrap">
                        <TwoCoinsSvg className="me-2" style={{width: "22px", height: "22px"}} />
                        <span className="fs-6 fw-semibold" style={{color: COLORS.header_text}}>зарплата</span>
                    </div>
                </div>
                <button className="w-100 px-3 py-2 btn mt-3 fw-semibold" onClick={createResumeHandler}
                        style={{backgroundColor: COLORS.neutral}}>Создать резюме</button>
            </div>
        </div>
    </div>
}